import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, Nav, TabPane, div, TabContent, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';
import YearDropdown from "./YearDropdown";
import EndYearDropdown from './EndYearDropdown'
import { FiChevronDown } from "react-icons/fi";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import combinedServices from "../services/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Warning from '../assets/warning.svg'
import close from "../assets/close.svg";

const initialEducation = {
    mbts_school: "",
    mbts_degree: "Degree",
    mbts_field_Of_study: "Field of Study",
    mbts_grade: "",
    mbts_startMonth: "Start Month",
    mbts_startYear: "Start Year",
    mbts_endMonth: "End Month",
    mbts_endYear: "End Year",
    mbts_description: "",
    errors: {
        schoolEmpty: "",
        degreeEmpty: "",
        fieldOfStudyEmpty: "",
        gradeEmpty: "",
        startMonthEmpty: "",
        endMonthEmpty: "",
        endYearEmpty: "",
        startYearEmpty: "",
        descriptionEmpty: ""
    }
};
//   if (resUserData.data.data.mbts_education_details.length > 0) {
//     setEducationFormArray(resUserData.data.data.mbts_education_details);
//   }
function Education(props) {
    const [customDiv, setCustomDiv] = useState(['div1']);
    const [educationFormArray, setEducationFormArray] = useState([
        initialEducation,
    ]);
    const [educationData, setEducationData] = useState([
        {
            id: 'div1',
        },
    ]);
    const [confiramtionpopup, setConfirmationPopup] = useState(false)
    const [backdrop, setBackdrop] = useState(false);
    const [keyboard, setKeyboard] = useState(true);
    const [startYear, setStartYear] = useState();
    const [index, setIndex] = useState();

    const addNewRow = () => {
        //   setCustomDiv([...customDiv, 'newDiv']);
        const formIsValid = handleValidation();
        if (formIsValid) {
            for (let i = 0; i < educationFormArray.length; i++) {
                let newErrors = {};
                if (educationFormArray.length > 0) {
                    let newArray = [...educationFormArray];
                    newArray[i] = { ...newArray[i], errors: newErrors };
                    setEducationFormArray(newArray);
                }
            }
            setEducationFormArray([...educationFormArray, initialEducation]);
        }
        else {
            toast.error("Please Fill Required Field", {
                position: "top-right",
            });
        }

    };
    const toggleConfimation = (index) => {
        setConfirmationPopup(!confiramtionpopup);
        setIndex(index)

    }

    const toogleCancel = () => {
        setConfirmationPopup(!confiramtionpopup);
    }
    const removeRow = async (id) => {
        const updatedData = educationFormArray.filter((item, index) => index !== id);
        setEducationFormArray(updatedData);
        setConfirmationPopup(!confiramtionpopup);
        let data = {
            userId: props.userId,
            mbts_education_details: updatedData

        };
        let token = localStorage.getItem("token");
        const exeperienceDetailsResponse =
            await combinedServices.createEducationDetails(data, token);
        if (exeperienceDetailsResponse.data.code == 200) {
            toast.success("Data Updated Successfully", {
                position: "top-right",
            });
        }

    };
    const handleValidation = () => {
        let formIsValid = true;
        const newErrors = {

        };
        for (let i = 0; i < educationFormArray.length; i++) {
            if (educationFormArray[i].mbts_school.length == 0) {
                newErrors.schoolEmpty = "Please enter school name.";
                formIsValid = false;
            }
            if (educationFormArray[i].mbts_degree == "Degree") {
                newErrors.degreeEmpty = "Please select degree name.";
                formIsValid = false;
            }
            if (educationFormArray[i].mbts_field_Of_study == "Field of Study") {
                newErrors.fieldOfStudyEmpty = "Please select field of study .";
                formIsValid = false;
            }
            // if (educationFormArray[i].mbts_grade.length == 0) {
            //     newErrors.gradeEmpty = "Please enter grade .";
            //     formIsValid = false;
            // }
            // if (educationFormArray[i].mbts_startMonth == "Start Month") {
            //     newErrors.startMonthEmpty = "Please enter start month .";
            //     formIsValid = false;
            // }
            // if (educationFormArray[i].mbts_endMonth == "End Month") {
            //     newErrors.endMonthEmpty = "Please enter end month .";
            //     formIsValid = false;
            // }
            // if (educationFormArray[i].mbts_startYear.length == 0) {
            //     newErrors.startYearEmpty = "Please enter start year .";
            //     formIsValid = false;
            // }
            // if (educationFormArray[i].mbts_endYear.length == 0) {
            //     newErrors.endYearEmpty = "Please enter end year .";
            //     formIsValid = false;
            // }


            // Set errors for the specific index
            // setEducationFormArray(prevState => {
            //     const newArray = [...prevState];
            //     newArray[i] = {
            //         ...newArray[i],
            //         errors: newErrors
            //     };
            //     return newArray;
            // });
            if (educationFormArray.length > 0) {
                let newArray = [...educationFormArray];
                newArray[i] = { ...newArray[i], errors: newErrors };
                setEducationFormArray(newArray);
            }

        }
        // setEducationFormArray({
        //     ...educationFormArray,
        //     errors: newErrors
        // });


        return formIsValid;
    }
    const handleChange2 = (e, index) => {
        const { name, value } = e.target;
        if (educationFormArray.length > 0) {
            let newArray = [...educationFormArray];
            newArray[index] = { ...newArray[index], [name]: value };
            setEducationFormArray(newArray);
        }
    };
    const fetchData = async () => {
        try {
            setEducationFormArray(props.educationFormArray);
        } catch (err) {
            return err
        }
    }
    useEffect(() => {
       // fetchData()
        setEducationFormArray(props.educationFormArray);
    }, [props]);
    // useEffect(() => {
    //     if (Array.isArray(props.educationFormArray)) {
    //         setEducationFormArray(props.educationFormArray);
    //     }
    // }, [props]);


    const handleUpdate = async () => {

        const formIsValid = handleValidation();
        if (formIsValid) {
            let data = {
                userId: props.userId,
                mbts_education_details: educationFormArray

            };
            let token = localStorage.getItem("token");
            const exeperienceDetailsResponse =
                await combinedServices.createEducationDetails(data, token);
            if (exeperienceDetailsResponse.data.code == 200) {
                toast.success("Data Updated Successfully", {
                    position: "top-right",
                });
            }
        }
    }



    return (
        <>
            <div className='edumain'>
                {educationFormArray?.map((item, index) => (
                    <div className="expense-block" key={item} id={`expense-block-${index}`} data-block={index}>
                        <Form>
                            <div className="editProfFrmBx">
                                <div className="editFrmOuter">
                                    <div className="frmBx study">
                                        <FormGroup className={`form_grp ${item?.errors?.schoolEmpty ? 'errorCont' : ''}`}>
                                            <Input readOnly type="Name" className="error" name="mbts_school" onChange={(e) => handleChange2(e, index)} value={item.mbts_school} id="exampleEmail" placeholder="School / College" />
                                            <small className="error_msg">{item.errors?.schoolEmpty}</small>
                                        </FormGroup>
                                    </div>
                                    <div className="frmBx study">
                                        <FormGroup className={`form_grp ${item?.errors?.degreeEmpty ? 'errorCont' : ''}`}>
                                            <Input disabled type="select" className='error' name="mbts_degree" onChange={(e) => handleChange2(e, index)} value={item.mbts_degree} id="exampleEmail">
                                                <option>Degree</option>
                                                <option>GED</option>
                                                <option>Certificate</option>
                                                <option>Associates</option>
                                                <option>Bachelors</option>
                                                <option>Masters</option>
                                                <option>Doctorate</option>
                                                <option>Licensure</option> 
                                            </Input>
                                            <FiChevronDown />
                                            <small className="error_msg">{item.errors?.degreeEmpty}</small>
                                        </FormGroup>


                                    </div>
                                    <div className="frmBx study">
                                        <FormGroup className={`form_grp ${item?.errors?.fieldOfStudyEmpty ? 'errorCont' : ''}`}>
                                           <Input disabled type="select" className='error' name="mbts_field_Of_study" onChange={(e) => handleChange2(e, index)} value={item.mbts_field_Of_study} id="exampleEmail">
                                          
                                    <option>Field of Study</option>
                                    <option>Accounting</option>
                                    <option>Aerospace Engineering</option>
                                    <option>Aerospace Sciences</option>
                                    <option>Agronomy</option>
                                    <option>Animal Science</option>
                                    <option>Architecture</option>
                                    <option>Art History</option>
                                    <option>Astronomy</option>
                                    <option>Biochemistry</option>
                                    <option>Biomedical Engineering</option>
                                    <option>Biology</option>
                                    <option>Bioinformatics</option>
                                    <option>Business Administration</option>
                                    <option>Chemical Engineering</option>
                                    <option>Chemistry</option>
                                    <option>Chemistry Education</option>
                                    <option>Civil Engineering</option>
                                    <option>Communications</option>
                                    <option>Computer Science</option>
                                    <option>Criminal Justice</option>
                                    <option>Data Science</option>
                                    <option>Early Childhood Education</option>
                                    <option>Economics</option>
                                    <option>Education</option>
                                    <option>Electrical Engineering</option>
                                    <option>Environmental Engineering</option>
                                    <option>Environmental Science</option>
                                    <option>Environmental Studies</option>
                                    <option>Equity Inclusion and Diversity</option>
                                    <option>Fashion Design</option>
                                    <option>Finance</option>
                                    <option>Fine Arts</option>
                                    <option>Food Science</option>
                                    <option>Gender Studies</option>
                                    <option>Geography</option>
                                    <option>Geology</option>
                                    <option>Graphic Design</option>
                                    <option>Health Sciences</option>
                                    <option>History</option>
                                    <option>Human Resources Management</option>
                                    <option>Industrial Engineering</option>
                                    <option>Information Technology</option>
                                    <option>Interior Design</option>
                                    <option>International Relations</option>
                                    <option>Journalism</option>
                                    <option>Journalism and Mass Communication</option>
                                    <option>Kinesiology</option>
                                    <option>Landscape Architecture</option>
                                    <option>Law</option>
                                    <option>Linguistics</option>
                                    <option>Management</option>
                                    <option>Marine Biology</option>
                                    <option>Marketing</option>
                                    <option>Mathematics</option>
                                    <option>Mechanical Design</option>
                                    <option>Mechanical Engineering</option>
                                    <option>Music</option>
                                    <option>Nursing</option>
                                    <option>Occupational Therapy</option>
                                    <option>Personal Development</option>
                                    <option>Petroleum Engineering</option>
                                    <option>Philosophy</option>
                                    <option>Physics</option>
                                    <option>Political Science</option>
                                    <option>Psychology</option>
                                    <option>Public Health</option>
                                    <option>Race Relations</option>
                                    <option>Radiologic Technology</option>
                                    <option>Recreation Therapy</option>
                                    <option>Religious Studies</option>
                                    <option>Social Work</option>
                                    <option>Sociology</option>
                                    <option>Spanish</option>
                                    <option>Speech Pathology</option>
                                    <option>Statistics</option>
                                    <option>Theater</option>
                                    <option>Urban Planning</option>
                                    <option>Wildlife Biology</option>
                                    <option>Other</option>

                                            </Input>
                                            <FiChevronDown />
                                            <small className="error_msg">{item.errors?.fieldOfStudyEmpty}</small>

                                        </FormGroup>
                                    </div>
                                    <div className="frmBx study">
                                        <FormGroup className={`form_grp ${item?.errors?.gradeEmpty ? 'errorCont' : ''}`}>
                                          <Input readOnly type="text" className='error' name="mbts_grade" onChange={(e) => handleChange2(e, index)} value={item.mbts_grade} id="exampleEmail" placeholder='Grade'/>
                                                                                      
                                            <small className="error_msg">{item.errors?.gradeEmpty}</small>
                                        </FormGroup>

                                    </div>
                                </div>
                                {/* <div className="editFrmOuter">
                                    <div className="frmBx study">
                                        <FormGroup className={`form_grp ${item?.errors?.startMonthEmpty ? 'errorCont' : ''}`}>
                                            <Input type="select" className='error' name="mbts_startMonth" onChange={(e) => handleChange2(e, index)} value={item.mbts_startMonth} id="exampleEmail">
                                                <option>Start Month</option>
                                                <option>January</option>
                                                <option>February</option>
                                                <option>March</option>
                                                <option>April</option>
                                                <option>May</option>
                                                <option>June</option>
                                                <option>July</option>
                                                <option>August</option>
                                                <option>September</option>
                                                <option>October</option>
                                                <option>November</option>
                                                <option>December</option>
                                            </Input>
                                            <FiChevronDown />
                                            <small className="error_msg">{item.errors?.startMonthEmpty}</small>
                                        </FormGroup>

                                    </div>
                                    <div className="frmBx study">
                                        <YearDropdown mbts_startYear={item.mbts_startYear} mbts_endYear={item.mbts_endYear} startYearEmpty={item?.errors?.startYearEmpty}
                                            index={index}
                                            handleChangeYear={handleChange2}
                                            T="startYear" />

                                        <FiChevronDown />
                                    </div>
                                    <div className="frmBx study">
                                        <FormGroup className={`form_grp ${item?.errors?.endMonthEmpty ? 'errorCont' : ''}`}>
                                            <Input type="select" className='error' name="mbts_endMonth" onChange={(e) => handleChange2(e, index)} value={item.mbts_endMonth} id="exampleEmail">
                                                <option>End Month</option>
                                                <option>January</option>
                                                <option>February</option>
                                                <option>March</option>
                                                <option>April</option>
                                                <option>May</option>
                                                <option>June</option>
                                                <option>July</option>
                                                <option>August</option>
                                                <option>September</option>
                                                <option>October</option>
                                                <option>November</option>
                                                <option>December</option>
                                            </Input>
                                            <FiChevronDown />
                                            <small className="error_msg">{item.errors?.endMonthEmpty}</small>
                                        </FormGroup>
                                    </div>
                                    <div className="frmBx study">
                                        <EndYearDropdown mbts_endYear={item.mbts_endYear} mbts_startYear={item.mbts_startYear} endYearEmpty={item?.errors?.endYearEmpty}
                                            index={index}
                                            handleChangeYear={handleChange2}
                                            T="endYear" />
                                        <FiChevronDown />
                                    </div>
                                </div> */}
                                <div className="textareaFrmOuter">
                                    <FormGroup >
                                        
                                        <Input readOnly type="textarea" name="mbts_description" onChange={(e) => handleChange2(e, index)} value={item.mbts_description} id="exampleText" placeholder="What do you/did you enjoy most about this position?" />
                                    </FormGroup>

                                </div>
                            </div>
                        </Form>
                        {/* <div className='removeBtn'><button onClick={handleRemoveParent} ><MdRemoveCircle /> Remove</button></div> */}
                        {/* <button onClick={() => removeRow(index)}>
                        <MdRemoveCircle /> Remove
                    </button> */}
                        <div className="removeBtn">
                            {index !== 0 && (
                                // <div className="remove_buttonBx">                           
                                //     <button onClick={() => removeRow(index)}>
                                //         <MdRemoveCircle /> Remove
                                //     </button>
                                // </div>
                                <div className="remove_buttonBx">
                                    <button onClick={() => toggleConfimation(index)}>
                                        <MdRemoveCircle /> Remove
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                ),)}

                {/* <button className='addmoreBtn' onClick={addNewRow}><MdAddCircle /> Add More</button>
                <div className="BtnsOuter">
                    <button className="cancelBtn" onClick={fetchData}>Cancel</button>
                    <button className="submitBtn" onClick={handleUpdate} >Update</button>
                </div> */}
            </div>

            <Modal isOpen={confiramtionpopup} toggle={toggleConfimation} backdrop={backdrop} keyboard={keyboard} className='mbtsModCont editData'>

                <ModalBody>
                    <Button
                        className="popCloseBtn"
                        type="button"
                        onClick={toogleCancel}>
                        <img src={close} alt="toggleDelete" />
                    </Button>
                    <div className="sure_message">
                        <i><img src={Warning} /></i>
                        <h2>Are You Sure?</h2>
                        <span>You want to delete the Question?</span>
                        <div className="modal_button">
                            <Button className="cancel_button" onClick={toogleCancel}>No</Button>
                            <Button className="confirmation_button" onClick={() => removeRow(index)}>Yes</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default Education;
import React, { Component } from "react";
import './Header.css'
import $ from "jquery";
import Logo from "../../../assets/mobnav_new.png";
import newLogo from "../../../assets/dash_updated_logo.png";

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    componentDidMount() {
    }

    logOut = () => {
     
        sessionStorage.removeItem('token')
        window.location.href = "/"
    }
    render() {
        return (
            <>
                <div className="afterLogin-page dashboard-wrappers">
                    <div className="dashheaderBx">
                        <div className="headLeftBx">
                            <div className="logoBx">
                                <a href="javascript:;">
                                    {/* <img src="https://mbts.flexsin.org/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo.12768cbc.png&w=256&q=75" alt=" " /> */}
                                    {/* <h2>MBTS</h2> */}
                                    {/* <img src={Logo} alt="" /> */}
                                    <img src={newLogo} alt="" />

                                </a>
                            </div>
                            <div className="hamburger-iconBox">
                                <a className="showMobMenu"><i></i><i></i><i></i></a>
                            </div>
                        </div>
                        <div className="dashHdrRight">
                            <div className="accountBox">
                                <a className="userLink">
                                    {/* <span className="userIconBox"><img src="/userImage.png" alt="" /></span> */}
                                </a>

                                <div className="dropdown show">
                                    <a className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                                    </a>

                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a className="dropdown-item" href="#" > <button type="submit" onClick={this.logOut.bind(this)} className="ac-logoutBtn">Logoutsss </button></a>

                                    </div>
                                </div>
                                {/* <ul className="accountMenu"  style={{display: "none"}}>                            
                        <li  ><a href="#">Logout</a></li>
                    </ul> */}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

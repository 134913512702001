import React from 'react'
import Header from "../Components/CommonComponents/Header/Header";
import Sidebar from '../Components/CommonComponents/Sidebar/Sidebar';
import DashboardMiddle from '../DashboardMiddle/dashboard';
import Footer from '../Components/CommonComponents/Footer/Footer'
import { hasToken } from '../utils/tokenUtil';
import { useNavigate } from 'react-router-dom';
const Dashboard = () =>{
    const navigate = useNavigate();
    if (!hasToken()) {
        navigate('/'); // Redirect to the login page or any other appropriate location
        return null; // Render nothing
      }
    return(
        <div className=''>
            <Header />
            <Sidebar />
            <DashboardMiddle/>
            <Footer /> 
        
        </div>
    )
}
export default Dashboard;
import React, { useState, useEffect } from "react";
import Axios from 'axios'
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, Input, FormGroup } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { FiChevronDown } from "react-icons/fi";
import { LuEye } from "react-icons/lu";
import './payments.css';
import { BiEdit } from "react-icons/bi"
import { MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";
import Warning from "../../src/assets/warning.svg"
import PayIcon from "../../src/assets/payicon.png"
import bankIcon from "../../src/assets/sbi-icon.png"
import paySucess from "../../src/assets/sucess-icon.png"
import PayFailed from "../../src/assets/failled.png"
import combinedServices from '../services/service'
import NoDataFound from '../assets/nodata.svg'
import ReactLoading from "react-loading";


const Paymants = (props) => {

    const [modal, setModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState([]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const [dataPerPage, setDataPerPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [limit, setLimit] = useState(10);
    const [model2Data , setModal2Data] = useState({});
    const [filter , setFilter] = useState('');
    const [loader, setLoader] = useState(true);


    const toggle = () => {
        // await combinedServices.deleteUserSubscription(data,token);
        setModal(!modal);
    };

    
    const [modal2, setModal2] = useState(false);
    const toggle2 = (data) => {
        setModal2Data(data);
        setModal2(!modal2);
    };
    const [modal3, setModal3] = useState(false);
    const toggle3 = () => {
        setModal3(!modal3);
    };

    useEffect(() => {
        // Call fetchData inside useEffect
        fetchData();
    }, [filter]);

    useEffect(() => {
        fetchData()
    }, [page]);
    useEffect(() => {
        fetchData()
    },[])

    useEffect(() => {
        fetchData()
    }, [search])
    const fetchData = async () => {
        try {
            const startIndexCalc = (page - 1) * limit + 1;
            setStartIndex(startIndexCalc);
           let data= {
                search: search,
                page: page,
                limit: limit, 
                filter:filter              
            }
            let token = localStorage.getItem("token");
            const paymentStatusResponse =
                await combinedServices.getPaymentStatus(data,token);
            if (paymentStatusResponse.data.code == 200) {
                setLoader(false)
                setPaymentStatus(paymentStatusResponse.data.data.array);
                let pageData = Math.ceil(paymentStatusResponse.data.data.total_count / limit)              
                setTotalCount(paymentStatusResponse.data.data.total_count)
                setDataPerPage(pageData)
            }

        } catch (error) {
            console.error("Error in getUserDetails request:", error);
        }
    };


    const handlePageClick = (event) => {
        setLoader(true);
        setPage(event.selected + 1);

    };


    function capitalizeFirstLetter(string) {
      
        return string[0].charAt(0).toUpperCase() + string[0].slice(1);
    }
    

    function capitalizeFirstLetter2(string) {
      
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    
 const handleFilter = (e) => {
 }
    return (
        <>
            <div className="middleSection-wrapper" >
                <div className="dashboardOuter">
                    <div className="rightSection">
                    <div className="container paym">
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '10px' }} className="paym_fil">
                            <div className="student_Title" >
                                <h1> Plan Status: {totalCount}</h1>
                            </div>
                            <div className="srchByfltOuter">
                                <div className="frmBx study srchBy">
                                    <FormGroup className="form_grp">
                                        <Input type="select" name="mbts_grade" onChange={(e) => setFilter(e.target.value)} >
                                            <option value=''>All</option>
                                            <option value='paid'>Paid</option>
                                            <option value='unpaid'>Unpaid</option>
                                            <option value='ACTIVE'>ACTIVE</option>
                                            <option value='INACTIVE'>INACTIVE</option>
                                        </Input>
                                        <FiChevronDown />
                                    </FormGroup>
                                </div>
                                <div className="input-group">
                                    <div className="form-outline">                                   
                                    
                                        <input type="search" placeholder="Search" name="search" value={search} onChange={(e) => setSearch
                                            (e.target.value)} id="form1" className="form-control searchBar" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="tableWrapper">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th width="60" scope="col">#</th>
                                        <th>User Name</th>
                                        <th>User Email</th>
                                        <th>Plan Name</th>
                                        <th>Plan Type</th>
                                        <th>Plan Price</th>
                                        <th>Invoice Number</th>
                                        <th>Payment Date</th>
                                        <th>Plan Status</th>
                                        <th>Payment Status</th>
                                        <th width="80" scope="col">Actions</th>
                                    </tr>
                                </thead>
                                        {loader ? (
                                        <tbody>

                                            <tr>
                                                <td colSpan={8} className="tbl_loaderWrp">
                                                    <div className="loaderCont">
                                                        <div className="d-flex justify-content-center align-items-center h-100">
                                                            <ReactLoading type="bars" color="#32B588" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        ) : (
                                <tbody>
                                    {
                                          paymentStatus.length > 0 ?(
                                        paymentStatus?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                   <td>{startIndex + index}</td>
                                                    <td>{data.firstName}{' '}{data.lastName}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.plan_type}</td>
                                                    <td>{data.plan_time}</td>
                                                    <td>$ {data.amount}</td>
                                                    <td>{data.payment_intent}</td>
                                                    {/* <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td> */}
                                                    <td className="planDate">{new Date(data?.createdAt).toLocaleString()}</td>
                                                    <td className={` ${data.plan_status == "INACTIVE"?'redClr':'greenClr'}`} >{data.plan_status}</td>
                                                    <td className="greenClr">{data.status ? capitalizeFirstLetter2(data.status) : ''}</td>
                                                    <td align="center">
                                                        <Button className="payment_viewBtn" onClick={()=>toggle2(data)}><MdOutlineRemoveRedEye /></Button>
                                                        {/* <Button className="delButton" onClick={toggle}><MdDelete /></Button> */}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                      
                                          ):(
                                            <tr>
                                               
                                            <td style={{ textAlign: 'center' }} colSpan={11}>
                                                <div className="noData">
                                                    <img src={NoDataFound} alt='nodatafound' />
                                                    <p>no data found</p>                                                        
                                                    </div>
                                                </td>
                                        </tr>
                                          )
                                    }
                                </tbody>
                                        )}
                            </table>
                        </div>
                        <div className="list-pagination">
                            {/* <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next"
                                    // onPageChange={handlePageClick}
                                    // pageRangeDisplayed={5}
                                    // marginPagesDisplayed={2}
                                    // pageCount={pageCount}
                                    previousLabel="previous"
                                    // renderOnZeroPageCount={null}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    activeClassName="active"
                                    containerClassName="custom-pagination" 
                                /> */}
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                pageCount={dataPerPage}
                                previousLabel="previous"
                                // renderOnZeroPageCount={null}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                activeClassName="active"
                                containerClassName="custom-pagination"
                            />
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*Delete Modal*/}
            <Modal isOpen={modal} toggle={toggle} className="modal_outer">
                <ModalBody>
                    <div className='sure_message'>
                        <i><img src={Warning} alt="" /></i>
                        <h2>Delete Plan</h2>
                        <p>Write <strong>&quot;Delete&quot;</strong> in the text field below to initiate deletion.
                        </p>
                        <div className="form_grp confGrp">
                            <input type="text" className="form_ctrl" placeholder='Type' />
                        </div>
                        <div className='modal_button'>
                            <Button className="cancel_button" onClick={toggle}>cancel</Button>
                            <Button className="confirmation_button">delete</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/*Delete Modal*/}
            {/*Payment Sucess Modal*/}
            <Modal isOpen={modal2} toggle={toggle2} className="modal_outer">
                <ModalBody>
                    <div className='sure_message'>
                        <h2>Payment Status</h2>
                        <div className="amountBx">
                            <strong>${model2Data?.amount} </strong>
                            {/* <img src={paySucess} alt="" /> */}
                            {/* <span>Dollor Thirty Nine Only</span> */}
                        </div>
                        {/* <div className="payto">
                            <span>To: Progressional </span>
                            <i><img src={PayIcon} alt="" /></i>
                        </div>
                        <div className="payfrom">
                            <span>
                                <strong>From:</strong>
                                State Bank of India
                            </span>
                            <i><img src={bankIcon} alt="" /></i>
                        </div> */}
                        <div className="orderId">
                            <span><strong>Invoice Number:</strong> {model2Data?.payment_intent}</span>
                            <span ><strong>Plan Type:</strong> {model2Data?.plan_type}</span>
                            <span ><strong>Payment Status:</strong> {model2Data.status ? capitalizeFirstLetter2(model2Data.status) : ''}</span>
                            <span ><strong>Payment Method:</strong> {model2Data.payment_method_types ? capitalizeFirstLetter(model2Data.payment_method_types) : ''}</span>
                            <span className="time"><strong>Payment Date:</strong> {new Date(model2Data?.createdAt).toLocaleString()}</span>
                        </div>
                        <div className='modal_button text-center closeBtn'>
                            <Button className="cancel_button" onClick={toggle2}>Close</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/*Payment Sucess Modal*/}

            {/*Payment Faild Modal*/}
            <Modal isOpen={modal3} toggle={toggle3} className="modal_outer">
                <ModalBody>
                    <div className='sure_message'>
                        <h2 className="redClr">Payment Faild</h2>
                        <div className="amountBx">
                            <strong>$39 <img src={PayFailed} alt="" /></strong>
                            <span>Dollor Thirty Nine Only</span>
                        </div>
                        <div className="payFailedcomment">Your Payment has been failed, due to insufficient account balance.</div>
                        <div className="payto">
                            <span>To: Progressional </span>
                            <i><img src={PayIcon} alt="" /></i>
                        </div>
                        <div className="payfrom">
                            <span>
                                <strong>From:</strong>
                                State Bank of India
                            </span>
                            <i><img src={bankIcon} alt="" /></i>
                        </div>
                        <div className="orderId">
                            <span>Order ID: 2024011007304<strong>70041</strong></span>
                            <span className="time">10 Jan 2024, 07:30 AM</span>
                        </div>
                        <div className='modal_button text-center closeBtn'>
                            <Button className="cancel_button" onClick={toggle3}>Close</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/*Payment Faild Modal*/}
        </>
    );
}

export default Paymants
"use client"
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, Input } from 'reactstrap';
import SaveAccount from './saveaccountpop';
import combinedServices from "../services/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from 'axios'

const initialBankDetail = {
    mbts_bankName: "",
    mbts_accountType: "",
    mbts_routingNumber: "",
    mbts_accountNumber: "",
    mbts_address1: "",
    mbts_address2: "",
    mbts_primaryAccount:false
}
const AddAccPopProps = (props) => {
    const [modal, setModal] = useState(false);
    const [bankname, setBankName] = useState();
    const [accountType, setAccountType] = useState();
    const [routingNumber, setRoutingNumber] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [address1, setAddress1] = useState();
    const [address2, setAddress2] = useState();
    const [bankDetails, setBankDetails] = useState([initialBankDetail])
    let [index , setLastIndex] = useState();

    const toggle = () => {
        setModal(!modal);
    };
    
    const handleChange4 = (e , indexs) => {
        const { name, value } = e.target;
        // Update the error state
       if(bankDetails[0].mbts_bankName.length==0){
        index=index-1
        setLastIndex(index);
       }       
            let newArray = [...bankDetails];            
            newArray[index] = { ...newArray[index], [name]: value };
            setBankDetails(newArray);
        // }

    };
    useEffect(() => {
        // Call fetchData inside useEffect
        setLastIndex(props.lastIndex);
        setBankDetails(props.bankDetails);
    }, [props]);
    
    const handleSubmit = async () => {
        try {
            let data = {
                userId: props.userId,
                mbts_bankDetails: bankDetails
            }

            let token = localStorage.getItem("token");
            const addBankDetailsResponse =
                await combinedServices.addBankDetails(data, token);
            if (addBankDetailsResponse.data.code == 200) {
                 await props.onSuccess();
                toggle()
                toast.success("Data Updated Successfully", {
                    position: "top-right",
                });
              
            }
        } catch (err) {
            return err
        }
    }
    const fetchData = async (userId) => {
        try {
            const response = await Axios.post("/admin/getUserProfileData", { user_id: userId });         
          
            if (response?.data?.data?.mbts_bankDetails.length > 0) {
                setBankDetails(response.data.data.mbts_bankDetails);
            }
          
        } catch (error) {
            console.error("Error in getUserDetails request:", error);
        }
    };


    return (
        <>
            <Button className="addaccBtn" onClick={toggle}>Add Bank Account</Button>

            <Modal isOpen={modal} toggle={toggle} className="modal_outer">
                <ModalBody>
                    <div className='add_bankaccount'>
                        <h3>Add Bank Account</h3>
                        <div className='bankfldBx'>
                            <Input type="text" name="mbts_bankName" className="form_ctrl" placeholder="Bank Name" value={bankDetails.mbts_bankName} onChange={(e) => handleChange4(e, index)}/>
                        </div>
                        <div className='bankfldBx'>
                            <Input type="select" name="mbts_accountType" className="form_ctrl form_sel" value={bankDetails.mbts_accountType} onChange={(e) => handleChange4(e, index)}>
                                <option>Account Type</option>
                                <option>Standard Accounts</option>
                            </Input>
                        </div>
                        <div className='bankfldBx'>
                            <Input type="number" name="mbts_routingNumber" className="form_ctrl" placeholder="Routing Number" 
                            value={bankDetails.mbts_routingNumber} onChange={(e) => handleChange4(e, index)}
                            onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                handleChange4(e, index);
                            }}
                            />
                        </div>
                        <div className='bankfldBx'>
                            <Input type="number" name="mbts_accountNumber" className="form_ctrl" placeholder="Account Number" 
                            value={bankDetails.mbts_accountNumber} onChange={(e) => handleChange4(e, index)} 
                            onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                handleChange4(e, index);
                            }}
                            />
                        </div>
                        <div className='CountFldOuter'>
                            {/* <div className='bankfldBx'>
                                <Input type="select" name="name" className="form_ctrl form_sel">
                                    <option>Country</option>
                                </Input>
                            </div>
                            <div className='bankfldBx'>
                                <Input type="select" name="name" className="form_ctrl form_sel">
                                    <option>City/State</option>
                                </Input>
                            </div> */}
                        </div>
                        <div className='bankfldBx'>
                            <Input type="text" name="mbts_address1" className="form_ctrl" placeholder="Address Line 1" value={bankDetails.mbts_address1} onChange={(e) => handleChange4(e, index)} />
                        </div>
                        <div className='bankfldBx'>
                            <Input type="text" name="mbts_address2" className="form_ctrl" placeholder="Address Line 2" value={bankDetails.mbts_address2} onChange={(e) => handleChange4(e, index)} />
                        </div>
                        <div className='modal_button'>
                            <Button className="cancel_button" onClick={toggle}>cancel</Button>
                            <Button className="confirmation_button" onClick={handleSubmit}>Save</Button>
                            <SaveAccount />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default AddAccPopProps;

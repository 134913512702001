import moment from "moment-timezone"

// convert local to  UTC
export function convertToUTC(startDateTime, endDateTime, timezone) {
    const splitString = timezone.split('(');
    const timeZoneName = splitString[0].trim();
    const utcStartDateTime = moment.tz(startDateTime, "YYYY-MM-DDTHH:mm:ss[Z]", timeZoneName).utc();
    const utcEndDateTime = moment.tz(endDateTime, "YYYY-MM-DDTHH:mm:ss[Z]", timeZoneName).utc();
    const utcFormatStartDateTime = utcStartDateTime.format();
    const utcFormatEndDateTime = utcEndDateTime.format();
    return { utcFormatStartDateTime, utcFormatEndDateTime }

}

// conver UTC to local
export function convertToLocal(startDateTime, endDateTime, timezone, ampm) {

    const splitString = timezone.split('(');
    const timeZoneName = splitString[0].trim();
    const localStartDateTime = moment.utc(startDateTime)
    const test = localStartDateTime.tz(timeZoneName);
    const localEndDateTime = moment.utc(endDateTime).tz(timeZoneName);
    if (ampm === "12") {
        const localStartTime = test?.format('h:mm A');
        const localEndTime = localEndDateTime?.format('h:mm A');
        return { localStartTime, localEndTime }
    }
    const localStartTime = test?.format('HH:mm');
    const localEndTime = localEndDateTime?.format('HH:mm');
    return { localStartTime, localEndTime }
}

//conver normal time (01:00) to Full date and time
export const normalTimeToFullDateAndTime = (time) => {
    const today = moment();
    const formattedTime = moment(time, "HH:mm");

    // Combine today's date with the time
    const combinedDateTime = moment({
        year: today.year(),
        month: today.month(),
        date: today.date(),
        hour: formattedTime.hour(),
        minute: formattedTime.minute()
    });
    return combinedDateTime.format()
}

export const convertTo12Hour = (time24, ampm) => {
    // ampm is a time format 12 or 24
    if (ampm == "12") {

        const [hours, minutes] = time24.split(':');
        let period = 'AM';
        let hours12 = parseInt(hours, 10);
        if (hours12 >= 12) {
            period = 'PM';
            if (hours12 > 12) {
                hours12 -= 12;
            }
        }
        if (hours12 === 0) {
            hours12 = 12;
        }

        return `${hours12}:${minutes} ${period}`;
    }

    return time24

};
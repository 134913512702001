import React, { useEffect, useState } from 'react';
import './dashboard.css'
import Axios from 'axios'
import { HiUsers } from "react-icons/hi";
import { FaQuestionCircle, FaWallet } from "react-icons/fa";
import { MdCrisisAlert } from "react-icons/md";
import { MdAccountTree } from "react-icons/md";
import { TiMessages } from "react-icons/ti";
import ReactPaginate from 'react-paginate';
import { Button, Modal, ModalBody, Input, FormGroup } from 'reactstrap';
import moment from 'moment'
import NoDataFound from '../assets/nodata.svg'
import DateRangeComp from "../DateRange/dateRange";
import { IoChatboxEllipses } from "react-icons/io5";
import combinedServices from '../services/service'
import ReactLoading from "react-loading";

const DashboardMiddle = (props) => {
    // const [totalCount, setTotalCount] = useState('0')
    // const [dateRange, setDateRange] = useState();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState("")
    const [dataPerPage, setDataPerPage] = useState(0)
    const [enquiryList, setEnquiryList] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [startIndex, setStartIndex] = useState(0)
    const [selectedInquiry, setSelectedInquiry] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [totalUser, setTotalUser] = useState(0);
    const [totalActiveUser, setTotalActiveUser] = useState(0);
    const [totalCoaches, setTotalCoaches] = useState(0);
    const [totalActiveCoaches, setTotalActiveCoaches] = useState(0);
    const [totalSubscription, setTotalSubscription] = useState(0);
    const [totalActiveSubscription, setTotalActiveSubscription] = useState(0);
    const [totalContacts, setTotalContacts] = useState(0);
    const [totalpayment, setTotalPayment] = useState(0);
    const [loader , setLoader] = useState(true);
    useEffect(() => {
        getDashboardData()
    }, [])
    const getDashboardData = async () => {
        let token = localStorage.getItem('token');
        const res = await combinedServices.getDashboardData(token);
        if (res.data.code == 200) {
            setLoader(false)
            setTotalUser(res.data.data.totalUsers);
            setTotalActiveUser(res.data.data.totalActiveUsers);
            setTotalCoaches(res.data.data.totalCoaches);
            setTotalActiveCoaches(res.data.data.totalActiveCoaches);
            setTotalSubscription(res.data.data.totalSubscriptions);
            setTotalActiveSubscription(res.data.data.totalActiveSubscriptions);
            setTotalContacts(res.data.data.totalContacts);
            setTotalPayment(res.data.data.totalAmount);
        }
    }
    const [dateRange, setDateRange] = useState();

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    const toggle1 = (email) => {
        const emailLink = `mailto:${email}`;
        window.location.href = emailLink;
    };
    const handleDateRangeChange = (selectedDates) => {

        if (selectedDates && selectedDates.length === 2) {
            setStartDate((selectedDates[0]));
            setEndDate((selectedDates[1]));

        }
    };

    const getEnquiryDetails = () => {
        let authenticationToken = localStorage.getItem('token');
        const startIndexCalc = (page - 1) * limit + 1;
        setStartIndex(startIndexCalc);
        Axios.get(`/getContactUsList`, {
            params: {
                search: search,
                page: page,
                limit: limit,
                startDate: startDate,
                endDate: endDate
            },
            headers: {
                Authorization: `Bearer ${authenticationToken}`
            }
        })
            .then((response) => {
                let pageData = Math.ceil(response.data.data.total / limit)
                setEnquiryList(response.data.data.contactUsList)
                setTotalCount(response.data.data.total)
                setDataPerPage(pageData)
            })
            .catch((error) => {

            })
    }
    useEffect(() => {
        
        getEnquiryDetails()
    }, [])
    useEffect(() => {
        getEnquiryDetails()
    }, [page])
    useEffect(() => {
        getEnquiryDetails()
    }, [startDate, endDate])

    useEffect(() => {
        getEnquiryDetails()
    }, [search])

    const handlePageClick = (event) => {
        setPage(event.selected + 1)
    };
    const openModal = (inquiry) => {
        // setSelectedInquiry(inquiry.message);
        setSelectedInquiry(inquiry);
        toggle();
    };
    return (
        <>

            <div className="middleSection-wrapper" >
                <div className="dashboardOuter">
                    <div className="rightSection">
                        <div className='dashcardOuter'>
                            <div className='dashcard'>
                                <a href='/dashboard/usermanagement'>
                                    <i><HiUsers /></i>
                                    <span>Total Users</span>
                                    <strong>{totalUser}</strong>
                                </a>
                            </div>
                            <div className='dashcard'>
                                <a href='/dashboard/usermanagement'>
                                    <i><HiUsers /></i>
                                    <span>Active Users</span>
                                    <strong>{totalActiveUser}</strong>
                                </a>
                            </div>
                            <div className='dashcard'>
                                <a href='/dashboard/accountability-coach'>
                                    <i><MdAccountTree /></i>
                                    <span>Accountability Coach</span>
                                    <strong>{totalCoaches}</strong>
                                </a>
                            </div>
                            <div className='dashcard'>
                                <a href='/dashboard/accountability-coach'>
                                    <i><MdAccountTree /></i>
                                    <span>Active Coach</span>
                                    <strong>{totalActiveCoaches}</strong>
                                </a>
                            </div>
                            {/* <div className='dashcard'>
                            <i><FaQuestionCircle /></i>
                            <span>Total Questions</span>
                            <strong>100K</strong>
                        </div>
                        <div className='dashcard'>
                            <i><FaQuestionCircle /></i>
                            <span>Active Questions</span>
                            <strong>100K</strong>
                        </div> */}
                            <div className='dashcard'>
                                <a href='/dashboard/subscription'>
                                    <i><MdCrisisAlert /></i>
                                    <span>Total Subscription Plans</span>
                                    <strong>{totalSubscription}</strong>
                                </a>
                            </div>
                            <div className='dashcard'>
                                <a href='/dashboard/subscription'>
                                    <i><MdCrisisAlert /></i>
                                    <span>Active Subscription Plans</span>
                                    <strong>{totalActiveSubscription}</strong>
                                </a>
                            </div>
                            <div className='dashcard'>
                                <a href='/dashboard/payments'>
                                    <i><FaWallet /></i>
                                    <span>Success Payments</span>
                                    <strong>{totalpayment >= 1000000 ? `$ ${(totalpayment / 1000000).toFixed(1)}M` : (totalpayment >= 1000 ? `$ ${(totalpayment / 1000).toFixed(1)}k` : `$ ${totalpayment}`)}</strong>
                                </a>
                            </div>
                            <div className='dashcard'>
                                <a href='/dashboard/inquiry'>
                                    <i><TiMessages /></i>
                                    <span>Inquiries</span>
                                    <strong>{totalContacts}</strong>
                                </a>
                            </div>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', fontSize: '10px' }}>
                                <div className="student_Title" >
                                    <h1>Inquiries Status</h1>
                                </div>
                                <div className="srchByfltOuter">
                                    <div className="frmBx study srchBy">
                                        <FormGroup className="form_grp">
                                            <Input type="date" name="mbts_grade" />
                                        </FormGroup>
                                    </div>
                                    <div className="input-group">
                                        <div className="form-outline">
                                            <input type="search" placeholder="Search" id="form1" className="form-control searchBar" name="search" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: '10px', marginBottom: '10px' }}>
                            <div className="student_Title" >
                                <h1>Inquiries Status: {totalCount}</h1>
                            </div>
                            <div className="srchByfltOuter">
                                <div className="frmBx study srchBy">

                                    <DateRangeComp dateRange={[startDate, endDate]} setDateRange={handleDateRangeChange} />

                                    {/* <DateRangeComp dateRange={dateRange} setDateRange={setDateRange} /> */}
                                </div>
                                <div className="input-group">
                                    <div className="form-outline">
                                        <input type="search" placeholder="Search" name="search" value={search} onChange={(e) => {setSearch
                                            (e.target.value); setPage(1)} } id="form1" className="form-control searchBar" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="tableWrapper">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th width="60" scope="col">S.No.</th>
                                    <th>Name</th>
                                    <th width="300">Email</th>
                                    <th>Subject</th>
                                    <th width="150">Date</th>
                                    <th width="180">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}

                        <div className="tableWrapper">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "5%" }}>#</th>
                                        <th style={{ width: "40%" }}>Name/ Email</th>
                                        {/* <th width="300">Email</th> */}
                                        <th style={{ width: "25%" }}>Subject</th>
                                        <th style={{ width: "10%" }}>Date</th>
                                        <th style={{ width: "10%" }}>Comments</th>
                                        <th style={{ width: "10%" }}>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        enquiryList.length > 0 ?
                                            enquiryList.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{startIndex + index}</td>
                                                        <td><span className="inqName">{data.name}</span>
                                                            <span className="dtxt">{data.email}</span></td>

                                                        {/* <td>{data.email}</td> */}
                                                        <td>{data.subject}</td>
                                                        <td className="planDate">  {moment(data.createdAt).format('MM-DD-YYYY hh:mm:A')}</td>
                                                        <td>
                                                            <Button className="viewcmtBtn" onClick={() => openModal(data)}>View</Button>


                                                        </td>
                                                        <td>
                                                            <Button className="viewcmtBtn" onClick={() => toggle1(data.email)}>Email</Button>


                                                        </td>
                                                    </tr>

                                                )

                                            })
                                            :
                                            <tr>

                                                <td style={{ textAlign: 'center' }} colSpan={6}>
                                                    <div className="noData">
                                                        <img src={NoDataFound} alt='nodatafound' />
                                                        <p>no data found</p>
                                                    </div>
                                                </td>
                                            </tr>

                                    }
                                    {/* <tr>
                                        <td>1</td>
                                        <td>David</td>
                                        <td>devidjohnes10@yopmail.com</td>
                                        <td>Inquiries for Subbscription Plan</td>
                                        <td className="planDate">04/01/2024<br /><small>11:35 AM</small></td>
                                        <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                    </tr> */}
                                    {/* <tr>
                                    <td>2</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>3</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>4</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>5</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>6</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>7</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>8</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>9</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                    {/* <tr>
                                    <td>10</td>
                                    <td>David</td>
                                    <td>devidjohnes10@yopmail.com</td>
                                    <td>Inquiries for Subbscription Plan</td>
                                    <td className="planDate">04/01/2024<br/><small>11:35 AM</small></td>
                                    <td><Button className="viewcmtBtn" onClick={toggle}>View Comments</Button></td>
                                </tr> */}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="list-pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next"
                                // onPageChange={handlePageClick}
                                // pageRangeDisplayed={5}
                                // marginPagesDisplayed={2}
                                // pageCount={pageCount}
                                previousLabel="previous"
                                // renderOnZeroPageCount={null}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                activeClassName="active"
                                containerClassName="custom-pagination"
                            />
                        </div> */}
                        <div className="list-pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next"
                                onPageChange={handlePageClick}
                                forcePage={page-1}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={2}
                                pageCount={dataPerPage}
                                previousLabel="previous"
                                // renderOnZeroPageCount={null}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                activeClassName="active"
                                containerClassName="custom-pagination"
                            />
                        </div>
                        {/* <div className="container">
                        <div className="row">
                            <div className="col-sm-4" style={{marginTop:'20px'}}>
                                <div className="card-box">
                                    <a href="#">
                                        <div className="totlBookingBx"><i className="yellowBg"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path></svg></i><strong>{totalCount}</strong><span>Total Users</span></div>
                                    </a>
                                </div>
                            </div>
                           
                        </div>
                    </div> */}
                    </div>
                </div>
            </div>
          
            {loader && (
        <>
          <div className="loaderCont">
            <div className="d-flex justify-content-center align-items-center h-100">
              <ReactLoading type="bars" color="#32B588" />
            </div>
          </div>
        </>
      )}
            {/*Delete Modal*/}
            {/* <Modal isOpen={modal} toggle={toggle} className="modal_outer">
                <ModalBody>
                    <div className='sure_message'>
                        <p className="commentTxt">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        <div className='modal_button' style={{ textAlign: 'center', display: 'block' }}>
                            <Button className="cancel_button" onClick={toggle}>Close</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal> */}

            <Modal isOpen={modal} toggle={toggle} className="modal_outer">
                <ModalBody>
                    {selectedInquiry && (
                        <>
                            <div className="cmtPop">
                                {/* <img src={chatIcon} alt="chat icon"/> */}
                                <span className="popIcn"><IoChatboxEllipses /></span>
                                <h2>comment from</h2>
                                <p className="inq_ppl">{selectedInquiry.name}</p>
                                <p> {(selectedInquiry.message)}</p>
                            </div>



                        </>
                    )}
                    <div className='modal_button' style={{ textAlign: 'center', display: 'block' }}>
                        <Button className="cancel_button" onClick={toggle}>Close</Button>
                    </div>
                </ModalBody>
            </Modal>
            {/*Delete Modal*/}
        </>
    )
}
export default DashboardMiddle;
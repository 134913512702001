import React, { useState, useEffect, useRef, MouseEvent as ReactMouseEvent, RefObject } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Input, FormGroup, Label } from 'reactstrap';
import classnames from 'classnames';
import { MdClose } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalcIcon from '../../src/assets/appointment-icon.png'
import moment from "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import styled from "styled-components";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { convertToUTC, convertToLocal } from "../utils/convertToUTC"
import combinedServices from "../services/service";
import { useParams } from 'react-router-dom';

const DropDownContainer = styled.div`
  width: 10.5em;
  margin: 0 auto;
`;
const DropDownHeader = styled.div`
  margin-bottom: 0.8em;
  padding: 0.4em 2em 0.4em 1em;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: 500;
  font-size: 1.3rem;
  color: #3faffa;
  background: #ffffff;
  cursor: pointer;
`;
const DropDownListContainer = styled.div``;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3faffa;
  font-size: 1.3rem;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;
const ListItem = styled.li`
  list-style: none;
  margin-bottom: 0.8em;
  cursor: pointer;
`;


const initialWeekTimeSlots = {
  Sunday: {
    mbts_startDate: "",
    day: "Sunday",
    mbts_timeSlots: [],
    startTime: "",
    endTime: "",
  },
  Monday: {
    mbts_startDate: "",
    day: "Monday",
    mbts_timeSlots: [],
    startTime: "",
    endTime: "",
  },
  Tuesday: {
    mbts_startDate: "",
    day: "Tuesday",
    mbts_timeSlots: [],
    startTime: "",
    endTime: "",
  },
  Wednesday: {
    mbts_startDate: "",
    day: "Wednesday",
    mbts_timeSlots: [],
    startTime: "",
    endTime: "",
  },
  Thursday: {
    mbts_startDate: "",
    day: "Thursday",
    mbts_timeSlots: [],
    startTime: "",
    endTime: "",
  },
  Friday: {
    mbts_startDate: "",
    day: "Friday",
    mbts_timeSlots: [],
    startTime: "",
    endTime: "",
  },
  Saturday: {
    mbts_startDate: "",
    day: "Saturday",
    mbts_timeSlots: [],
    startTime: "",
    endTime: "",
  },
};


const Availabelity = () => {
  const [activeTab, setActiveTab] = useState('1');
  const [timezones, setTimezones] = useState([]);
  const [weekTimeSlots, setWeekTimeSlots] = useState(initialWeekTimeSlots);
  const [timezone, setTimezone] = useState("");
  const [selectedHourFormat, setSelectedHourFormat] = useState("12");
  const [activeAvailabilityTab, setactiveAvailabilityTab] = useState(activeTab);
  const [mbtsTimeSlots, setMbtsTimeSlots] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [showAddTimeSlotButton, setShowAddTimeSlotButton] = useState(true);
  const [currentTimeZone, setCurrentTimeZone] = useState(timezone);
  const [showClear, setShowClear] = useState(true);
  const [isDrop3, setIsDrop3] = useState(false);
  const [costPerHour, setCostPerHour] = useState("")
  const dropdownRef = useRef(null);
  // const [disablePastTime, setDisablePastTime] = useState(true);

  const { userId } = useParams();

  useEffect(() => {
    fetchData(); // Call the async function
  }, []);

  async function fetchData() {
    try {
      let token = localStorage.getItem("token");
      const resUserData = await combinedServices.getCoachProfileData(
        token, userId
      );

      if (resUserData?.data?.data?.mbts_costAndAvailability?.length > 0) {
        setCostPerHour(resUserData?.data?.data?.mbts_costAndAvailability[0]?.mbts_costPerHour)
        setTimezone(resUserData?.data?.data?.mbts_costAndAvailability[0].timeZone)
        setCurrentTimeZone(resUserData?.data?.data?.mbts_costAndAvailability[0].timeZone)
        setMbtsTimeSlots(
          resUserData?.data?.data?.mbts_costAndAvailability[0]
            .mbts_currentTimeSlots
        );
        setSelectedHourFormat(
          resUserData?.data?.data?.mbts_costAndAvailability[0].timeFormat
        );
        setactiveAvailabilityTab(
          resUserData?.data?.data?.mbts_costAndAvailability[0]
            .activeAvailabilityTab
        );

        if (!resUserData?.data?.data?.mbts_costAndAvailability[0].timeZone) {
          // Get current time zone
          const currentTimezone = moment.tz.guess();
          const fullTimezoneName = moment.tz(currentTimezone).zoneAbbr();
          const utcOffset = moment.tz(currentTimezone).format('Z');
          const formattedTimezone = `${currentTimezone} (UTC ${utcOffset})`;
          setTimezone(formattedTimezone)
        } else {
          setTimezone(
            resUserData?.data?.data?.mbts_costAndAvailability[0].timeZone
          );
        }

        setWeekTimeSlots(
          resUserData?.data?.data?.mbts_costAndAvailability[0]
            .mbts_recurringTimeSlots
        );
      } else {

        // Get current time zone
        const currentTimezone = moment.tz.guess();
        const fullTimezoneName = moment.tz(currentTimezone).zoneAbbr();
        const utcOffset = moment.tz(currentTimezone).format('Z');
        const formattedTimezone = `${currentTimezone} (UTC ${utcOffset})`;
        setTimezone(formattedTimezone)
      }
    } catch (err) {
      console.error(err);
    }
  }


  useEffect(() => {
    setCurrentTimeZone(timezone)
  }, [])

  // getting timezone list
  useEffect(() => {
    const allTimezones = moment.tz.names();

    // Create an array of objects with the timezone name and UTC offset
    const timezonesWithOffset = allTimezones.map((timezone) => ({
      name: timezone,
      offset: moment.tz(timezone).format("Z"),
    }));

    setTimezones(timezonesWithOffset);
  }, []);

  const handleAddButton = () => {
    const TimeUTC = convertToUTC(moment(startTime?.toDate()).format("YYYY-MM-DDTHH:mm:ss[Z]"), moment(endTime?.toDate()).format("YYYY-MM-DDTHH:mm:ss[Z]"), currentTimeZone)

    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    let startDateConverted = startDate.toLocaleDateString('en-GB', options)

    const isDateAdded = mbtsTimeSlots.find((startDate) => {
      return startDate.mbts_startDate === startDateConverted
    })
    const isTimeAdded = isDateAdded?.mbts_timeSlots.find((slot) => {
      return slot.mbts_startTime == TimeUTC.utcFormatStartDateTime
    })

    if (isTimeAdded) {
      toast.error("Slot already added")
      return
    }
    if (startTime && endTime) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      let startDateConverted = startDate.toLocaleDateString('en-GB', options)
      if (startDate && startTime && endTime) {
        const existingDateIndex = mbtsTimeSlots.findIndex(
          (timeSlot) =>
            timeSlot.mbts_startDate === startDateConverted
        );
        if (existingDateIndex !== -1) {
          // Date already exists, append the new time slot
          const TimeUTC = convertToUTC(moment(startTime?.toDate()).format("YYYY-MM-DDTHH:mm:ss[Z]"), moment(endTime?.toDate()).format("YYYY-MM-DDTHH:mm:ss[Z]"), currentTimeZone)
          const updatedTimeSlotsWithUTC = [...mbtsTimeSlots];
          updatedTimeSlotsWithUTC[existingDateIndex].mbts_timeSlots.push({
            mbts_startTime: TimeUTC.utcFormatStartDateTime,
            mbts_endTime: TimeUTC.utcFormatEndDateTime
          });
          setMbtsTimeSlots(updatedTimeSlotsWithUTC);
        } else {
          // Date doesn't exist, create a new entry
          const TimeUTC = convertToUTC(moment(startTime?.toDate()).format("YYYY-MM-DDTHH:mm:ss[Z]"), moment(endTime?.toDate()).format("YYYY-MM-DDTHH:mm:ss[Z]"), currentTimeZone)
          const newTimeSlotWithUTC = {
            mbts_startDate: startDateConverted,
            mbts_timeSlots: [
              {
                mbts_startTime: TimeUTC.utcFormatStartDateTime,
                mbts_endTime: TimeUTC.utcFormatEndDateTime
              },
            ],
            startTime: "",
            endTime: "",
            day: "",
          };
          setMbtsTimeSlots([...mbtsTimeSlots, newTimeSlotWithUTC]);
          // setParseTimeSlot([...mbtsTimeSlots, newTimeSlot])
        }

        setStartDate(new Date());
        setStartTime(null);
        setEndTime(null);
        // setError(null);
      } else {
        // Handle validation or show an error message
        // setError("Please fill in all fields");
      }
    } else {
      toast.error("Please select slot time, endTime > startTime")
    }
  };

  const handleSlotDelete = (dateIndex, slotIndex) => {
    const updatedTimeSlots = [...mbtsTimeSlots];
    updatedTimeSlots[dateIndex].mbts_timeSlots.splice(slotIndex, 1);

    // If no more time slots for that date, remove the date entry
    if (updatedTimeSlots[dateIndex].mbts_timeSlots.length === 0) {
      updatedTimeSlots.splice(dateIndex, 1);
    }

    setMbtsTimeSlots(updatedTimeSlots);
  };

  const [weekCollapses, setWeekCollapses] = useState({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });

  // <Dayjs | null>(dayjs(null))

  const [weekStartTimeInputs, setWeekStartTimeInputs] = useState({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });

  const [weekEndTimeInputs, setWeekEndTimeInputs] = useState({
    Sunday: null,
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: null,
  });

  const toggleCollapse = (day) => {
    setShowAddTimeSlotButton(!showAddTimeSlotButton);
    setShowClear(!showClear);
    setWeekCollapses((prevCollapses) => ({
      ...prevCollapses,
      [day]: !prevCollapses[day],
    }));
  };

  const handleAddTimeSlot = (day) => {

    if (!weekStartTimeInputs[day]) {
      toast.warning("Please select slot time, endTime > startTime")
      return
    }

    if (!weekEndTimeInputs[day]) {
      toast.warning("Please select slot time, endTime > startTime")
      return
    }
    // const newTimeSlot: TimeSlot = {
    //   mbts_startDate: "", // You need to set the start date
    //   day,
    //   mbts_timeSlots: [
    //     {
    //       mbts_startTime: weekStartTimeInputs[day],
    //       mbts_endTime: weekEndTimeInputs[day],
    //     },
    //   ],
    //   startTime: "",
    //   endTime: "",
    // };

    // // Check if the day already exists in weekTimeSlots
    // if (weekTimeSlots[day]) {
    //   // If it exists, update mbts_timeSlots for that day
    //   setWeekTimeSlots(
    //     (prevTimeSlots: { [x: string]: { mbts_timeSlots: any } }) => ({
    //       ...prevTimeSlots,
    //       [day]: {
    //         ...prevTimeSlots[day],
    //         mbts_timeSlots: [
    //           ...prevTimeSlots[day].mbts_timeSlots,
    //           {
    //             mbts_startTime: weekStartTimeInputs[day],
    //             mbts_endTime: weekEndTimeInputs[day],
    //           },
    //         ],
    //       },
    //     })
    //   );
    // } else {
    //   // If it doesn't exist, add a new entry
    //   setWeekTimeSlots((prevTimeSlots: any) => ({
    //     ...prevTimeSlots,
    //     [day]: newTimeSlot,
    //   }));
    // }
    // const startTime = normalTimeToFullDateAndTime(weekStartTimeInputs[day])
    // const endTime = normalTimeToFullDateAndTime(weekEndTimeInputs[day])

    const startEndTimeInUTC = convertToUTC(moment(weekStartTimeInputs[day]?.toDate()).format("YYYY-MM-DDTHH:mm:ss[Z]"), moment(weekEndTimeInputs[day]?.toDate()).format("YYYY-MM-DDTHH:mm:ss[Z]"), currentTimeZone)

    const isSlotAdded = weekTimeSlots[day].mbts_timeSlots.find((slot) => {
      return slot.mbts_startTime == startEndTimeInUTC.utcFormatStartDateTime
    })
    if (isSlotAdded) {
      toast.error("Slot already added")
      return
    }

    const newTimeSlot = {
      mbts_startDate: "", // You need to set the start date
      day,
      mbts_timeSlots: [
        {
          mbts_startTime: startEndTimeInUTC.utcFormatStartDateTime,
          mbts_endTime: startEndTimeInUTC.utcFormatEndDateTime,
        },
      ],
      startTime: "",
      endTime: "",
    };

    // Check if the day already exists in weekTimeSlots
    if (weekTimeSlots[day]) {
      // If it exists, update mbts_timeSlots for that day
      setWeekTimeSlots((prevTimeSlots) => ({
        ...prevTimeSlots,
        [day]: {
          ...prevTimeSlots[day],
          mbts_timeSlots: [
            ...prevTimeSlots[day].mbts_timeSlots,
            {
              mbts_startTime: startEndTimeInUTC.utcFormatStartDateTime,
              mbts_endTime: startEndTimeInUTC.utcFormatEndDateTime,
            },
          ],
        },
      }));
    } else {
      // If it doesn't exist, add a new entry
      setWeekTimeSlots((prevTimeSlots) => ({
        ...prevTimeSlots,
        [day]: newTimeSlot,
      }));
    }
    setWeekStartTimeInputs({
      Sunday: null,
      Monday: null,
      Tuesday: null,
      Wednesday: null,
      Thursday: null,
      Friday: null,
      Saturday: null,
    });
    setWeekEndTimeInputs({
      Sunday: null,
      Monday: null,
      Tuesday: null,
      Wednesday: null,
      Thursday: null,
      Friday: null,
      Saturday: null,
    });
    toggleCollapse(day);
    setShowAddTimeSlotButton(true);
  };

  const handleRemoveTimeSlot = (day, index) => {
    setWeekTimeSlots((prevTimeSlots) => {
      const currentDayTimeSlot = prevTimeSlots[day];

      // Ensure the current day exists in the state
      if (currentDayTimeSlot) {
        const updatedTimeSlots = currentDayTimeSlot.mbts_timeSlots.filter(
          (_, i) => i !== index
        );

        // Update the state only if there are changes
        if (
          updatedTimeSlots.length !== currentDayTimeSlot.mbts_timeSlots.length
        ) {
          return {
            ...prevTimeSlots,
            [day]: {
              ...currentDayTimeSlot,
              mbts_timeSlots: updatedTimeSlots,
            },
          };
        }
      }

      // If the day or index is not valid, return the previous state unchanged
      return prevTimeSlots;
    });
  };

  const toggling3 = () => setIsDrop3(!isDrop3);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  // useEffect(() => {
  //   // start date === current date disable past time
  //   if (moment(startDate).format("YYYY-MM-DD") === getCurrentDate()) {
  //     setDisablePastTime(true);
  //   } else {
  //     setDisablePastTime(false);
  //   }
  // }, [startDate]);

  const clearRecurringTime = (timeSlots, day) => {
    setWeekTimeSlots((prevTimeSlots) => {
      const currentDayTimeSlot = prevTimeSlots[day];
      // Ensure the current day exists in the state
      if (currentDayTimeSlot) {
        // Update the state only if there are changes
        return {
          ...prevTimeSlots,
          [day]: {
            ...currentDayTimeSlot,
            mbts_timeSlots: [],
          },
        };
      }
    });
  }





  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [collapse, setCollapse] = useState(false);

  const toggleCol = () => {
    setCollapse(!collapse);
  };

  const [isVisible, setIsVisible] = useState(true);

  const handleButtonClick = () => {
    setIsVisible(false);
  };


  const handleUpdateButton = async () => {
    let data = {
      mbts_costAndAvailability: [
        {

          mbts_costPerHour: costPerHour,
          mbts_recurringTimeSlots: weekTimeSlots,
          timeZone: timezone,
          timeFormat: selectedHourFormat,
          activeAvailabilityTab: activeAvailabilityTab,
          mbts_currentTimeSlots: mbtsTimeSlots,
        },
      ],
    };
    const sendingData = { data, userId }
    let token = localStorage.getItem("token");
    const educationDetailsResponse = await combinedServices.createCoachAndAvailability(sendingData, token);
    if (educationDetailsResponse.data.code == 200) {
      toast.success("Data Updated Successfully", {
        position: "top-right",
      });
      fetchData();
    }
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDrop3(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Input
        value={costPerHour}
        type="number"
        className="form_ctrl form_sel"
        name="firstName"
        id="exampleEmail"
        placeholder="Cost"
        onChange={(e) => {
          // const value = test()
          setCostPerHour(e.target.value)
        }}
      />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle("1")}
          >
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  checked={activeTab === "1"} // Check if this radio is selected
                // onChange={() => setCustomeTimeSlot("Recurring Time Slots")}
                />
                Recurring Time Slots
              </Label>
            </FormGroup>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle("2")}
          >
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="Custom Time Slots" // Assuming you want to set a specific value
                  checked={activeTab === "2"} // Check if this radio is selected
                // onChange={() => setCustomeTimeSlot("Custom Time Slots")}
                />
                Custom Time Slots
              </Label>
            </FormGroup>
          </NavLink>
        </NavItem>
      </Nav>
      <div className="slotTimezoneBx">
        <div className="slotTime_Left">
          {/* <div className="form_grp">
            <Input
              type="select"
              name="timezone"
              className="form_ctrl form_sel"
              id="exampleSelect"
              value={selectedTimezone}
              onChange={(e) => {
                const selectedTimezone = e.target.value;
                setSelectedTimezone(selectedTimezone);
                setTimezone(selectedTimezone);
              }}
            >
              <option value="">Select a timezone</option>
              {timezones.map((timezone:any) => (
                <option key={timezone} value={timezone}>
                  {timezone.name} (UTC{" "} {timezone.offset})
                </option>
              ))}
            </Input>
          </div> */}
          <div className="form_grp">
            <DropDownContainer 
            ref={dropdownRef}
              className="custom_select">
              <DropDownHeader onClick={toggling3}>
                {timezone}
              </DropDownHeader>
              {isDrop3 && (
                <DropDownListContainer className="drop_cont">
                  <DropDownList className="drop_list">
                    {timezones.map((tz) => (
                      <ListItem
                        onClick={() => {
                          const timezoneString = `${tz.name} (UTC ${tz.offset})`;
                          setCurrentTimeZone(timezoneString);
                          setIsDrop3(!isDrop3);
                          setTimezone(timezoneString);
                        }}
                        key={`${tz.name}-${tz.offset}`} // Assuming tz.name and tz.offset uniquely identify each timezone
                      >
                        {tz.name} (UTC {tz.offset})
                      </ListItem>
                    ))}
                  </DropDownList>
                </DropDownListContainer>
              )}
            </DropDownContainer>
          </div>
        </div>
        <div className="slotTime_Right">
          <span>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio2"
                  checked={selectedHourFormat === "12"}
                  onChange={() => {
                    setSelectedHourFormat("12")
                    setSelectedHourFormat("12")
                  }
                  }
                />
                12 Hours
              </Label>
            </FormGroup>
          </span>
          <span>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio2"
                  checked={selectedHourFormat === "24"}
                  onChange={() => {
                    setSelectedHourFormat("24")
                    setSelectedHourFormat("24")
                  }
                  }
                />
                24 Hours
              </Label>
            </FormGroup>
          </span>
        </div>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="slotsListOuter">
            <div className="slotBxInn">
              {weekTimeSlots &&
                Object.keys(weekTimeSlots).map((day) => (
                  <div key={day} className="dayNameOuter">
                    <div className="dayNameHd">{day}</div>
                    <div className="addslote">
                      {showClear && <Button onClick={() => clearRecurringTime(weekTimeSlots, day)} className="clearBtn">Clear</Button>}
                      {showAddTimeSlotButton && (
                        <Button
                          className="TimeslotBtn"
                          onClick={() => toggleCollapse(day)}
                        >
                          Add Timeslots
                        </Button>
                      )}
                    </div>
                    {weekCollapses[day] && (

                      <div className="slotFrmBx">
                        <Button
                          className="clearBtn"
                          onClick={() => toggleCollapse(day)}
                        >
                          Cancel
                        </Button>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div className="slottimeBx">
                            <TimePicker
                              value={weekStartTimeInputs[day]}
                              onChange={(newValue) => {
                                setWeekStartTimeInputs({
                                  ...weekStartTimeInputs,
                                  [day]: newValue,
                                })
                                if (newValue && weekEndTimeInputs[day] && newValue.isAfter(weekEndTimeInputs[day])) {
                                  setWeekEndTimeInputs({
                                    ...weekEndTimeInputs,
                                    [day]: null,
                                  })
                                }
                              }}
                              ampm={selectedHourFormat === "12" ? true : false}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                            />
                          </div>
                          <div className="slottimeBx">
                            <TimePicker
                              disabled={!weekStartTimeInputs[day]}
                              value={weekEndTimeInputs[day]}
                              onChange={(newValue) => {
                                setWeekEndTimeInputs({
                                  ...weekEndTimeInputs,
                                  [day]: newValue,
                                })
                                if (newValue && weekStartTimeInputs[day] && newValue.isBefore(weekStartTimeInputs[day])) {
                                  setWeekEndTimeInputs({
                                    ...weekEndTimeInputs,
                                    [day]: null,
                                  })
                                }
                              }}
                              ampm={selectedHourFormat === "12" ? true : false}
                              slotProps={{
                                textField: {
                                  error: false,
                                },
                              }}
                            />
                          </div>
                        </LocalizationProvider>
                        <Button
                          className="TimeslotBtn"
                          onClick={() => handleAddTimeSlot(day)}
                        >
                          Add Time Slot
                        </Button>
                      </div>
                    )}
                    <div className="slotList">
                      <ul>
                        {weekTimeSlots[day]?.mbts_timeSlots.map(
                          (slot, index) => (
                            <li key={index}>
                              <span>{convertToLocal(slot.mbts_startTime, slot.mbts_endTime, currentTimeZone, selectedHourFormat).localStartTime} -</span>
                              <span>{convertToLocal(slot.mbts_startTime, slot.mbts_endTime, currentTimeZone, selectedHourFormat).localEndTime}</span>
                              <Button
                                onClick={() => handleRemoveTimeSlot(day, index)}
                              >
                                <MdClose />
                              </Button>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <div className="bookedslotsNumb">
                      Time Slots
                      <span>
                        {weekTimeSlots[day]?.mbts_timeSlots.length}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="customSloteOuter">
            <div className="customslotBx">
              <div className="customdateBx">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
                <i>
                  <img src={CalcIcon} alt="calender_icon" />
                </i>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="customdateBx">
                  {/* <Input
                    type="time"
                    name="startTime"
                    className="customDate"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  /> */}

                  <TimePicker
                    value={startTime}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                      if (newValue && endTime && newValue.isAfter(endTime)) {
                        setEndTime(null);
                      }
                    }}
                    ampm={selectedHourFormat === "12" ? true : false}
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                  />
                </div>
                <div className="customdateBx">
                  {/* <Input
                    type="time"
                    name="endTime"
                    className="customDate"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  /> */}
                  <TimePicker
                    disabled={!startTime}
                    value={endTime}
                    onChange={(newValue) => {
                      setEndTime(newValue);
                      if (newValue && startTime && newValue.isBefore(startTime)) {
                        setEndTime(null);
                      }
                    }}
                    ampm={selectedHourFormat === "12" ? true : false}
                    // minTime={dayjs(startTime?.toDate())}
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                  />
                </div>
              </LocalizationProvider>
              <div className="addDelBtnBx">
                <Button className="addBtn" onClick={handleAddButton}>
                  Add
                </Button>
              </div>
            </div>
            <div className="bookedslotList">
              {mbtsTimeSlots?.map((timeSlot, dateIndex) => (
                <div key={dateIndex} className="bookedSlotItem">
                  <h3>Booked slot date: {new Date(timeSlot.mbts_startDate.split('/').reverse().join('/')).toLocaleDateString('en-US')}</h3>
                  {timeSlot.mbts_timeSlots.map((slot, slotIndex) => {
                    const localTime = convertToLocal(slot.mbts_startTime, slot.mbts_endTime, currentTimeZone, selectedHourFormat)
                    return (
                      <span key={slotIndex}>
                        {`${localTime.localStartTime} - ${localTime.localEndTime}`}
                        <br />
                        <Button
                          className="slotdel"
                          onClick={() => handleSlotDelete(dateIndex, slotIndex)}
                        >
                          <MdClose />
                        </Button>
                      </span>
                    )
                  }

                  )}
                </div>
              ))}
            </div>
          </div>
        </TabPane>
      </TabContent>
      <div className="BtnsOuter">
        <button className="cancelBtn">Cancel</button>
        <button className="submitBtn" onClick={handleUpdateButton}>Update</button>
      </div>
    </div>
  );
};

export default Availabelity;

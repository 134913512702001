//Import------------------------------------------------------------------------------------
import axios from "axios";
//END---------------------------------------------------------------------------------------

/**
 * FUNCTION STARTS FROM HERE
 */

/*  Experience Details
 * @createdBy Ankit
 */
async function createExperienceDetails(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/createExperienceDetails", data, {
    headers,
  });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
/*  Create Subscription Plan
 * @createdBy Ankit
 */
async function saveSubscriptionPlan(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/saveSubscriptionPlan", data, {
    headers,
  });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Get Subscription Plan
 * @createdBy Ankit
 */
async function getAllSubscriptionPlan(token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get("/admin/getAllSubscriptionPlan", { headers });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Get Subscription Plan
 * @createdBy Ankit
 */
async function getAllSubscriptionPlanTable(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/getAllSubscriptionPlanTable", data, {
    headers,
  });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Experience Details
 * @createdBy Ankit
 */
async function createEducationDetails(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/createEducationDetails", data, {
    headers,
  });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Personal Details
 * @createdBy Ankit
 */
async function createUserPersonalInfomation(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/createUserPersonalInfomation", data, {
    headers,
  });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Delete Subscription
 * @createdBy Ankit
 */
async function deleteSubscription(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.delete("/admin/deleteTask?data=" + data._id, {
    headers,
  });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*  Delete Subscription
 * @createdBy Ankit
 */
async function updateStatusSubscription(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/updateStatusSubscription", data, {
    headers,
  });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
// /*  Create Subscription Plan
//  * @createdBy Ankit
//  */
// async function saveSubscriptionPlan(data, token) {
//   const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     "Content-Type": "application/json", // You can add other headers as needed
//   };
//   let response = await axios.post("/admin/saveSubscriptionPlan", data, {
//     headers,
//   });

//   try {
//     let response_data = response;
//     return response_data;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// }

// /*  Get Subscription Plan
//  * @createdBy Ankit
//  */
// async function getAllSubscriptionPlan(token) {
//   const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     "Content-Type": "application/json", // You can add other headers as needed
//   };
//   let response = await axios.get("/admin/getAllSubscriptionPlan", { headers });

//   try {
//     let response_data = response;
//     return response_data;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// }

// /*  Experience Details
//  * @createdBy Ankit
//  */
// async function createEducationDetails(data, token) {
//   const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     "Content-Type": "application/json", // You can add other headers as needed
//   };
//   let response = await axios.post("/admin/createEducationDetails", data, {
//     headers,
//   });

//   try {
//     let response_data = response;
//     return response_data;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// }

// /*  Personal Details
//  * @createdBy Ankit
//  */
// async function createUserPersonalInfomation(data, token) {
//   const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     "Content-Type": "application/json", // You can add other headers as needed
//   };
//   let response = await axios.post("/admin/createUserPersonalInfomation", data, {
//     headers,
//   });

//   try {
//     let response_data = response;
//     return response_data;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// }

/*  Delete Subscription
 * @createdBy Ankit
 */
// async function deleteSubscription(data, token) {
//   const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     "Content-Type": "application/json", // You can add other headers as needed
//   };
//   let response = await axios.delete("/admin/deleteTask?data=" + data._id, {
//     headers,
//   });

//   try {
//     let response_data = response;
//     return response_data;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// }

// /*  Delete Subscription
//  * @createdBy Ankit
//  */
// async function updateStatusSubscription(data, token) {
//   const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
//   const headers = {
//     Authorization: `Bearer ${token}`,
//     "Content-Type": "application/json", // You can add other headers as needed
//   };
//   let response = await axios.post("/admin/updateStatusSubscription", data, {
//     headers,
//   });

//   try {
//     let response_data = response;
//     return response_data;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// }

/*  Personal Details
 * @createdBy Ankit
 */
async function getUserProfileData(token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get("/admin/getUserProfileData", { headers });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*Add Bank Details
 * @createdBy Ankit
 */
async function addBankDetails(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/addBankDetails", data, { headers });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*Add Bank Details
 * @createdBy Ankit
 */
async function getDetailsSubscription(_id, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get(
    "/admin/getDetailsSubscriptionForAdmin?_id=" + _id,
    { headers }
  );

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getDetailsSubscriptionForUser(subscription_id, userId, token) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get(baseUrl + "/getDetailsSubscriptionForUser", {
    params: {
      subscription_id: subscription_id,
      userId: userId,
    },
    headers: headers,
  });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/*Add Bank Details
 * @createdBy Ankit
 */
async function getDashboardData(token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.get("/admin/getDashboardData", { headers });


  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/* Get Payment Status
 * @createdBy Ankit
 */
async function getPaymentStatus(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let response = await axios.get(
    `/admin/getPaymentStatus?search=${data.search}&page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
    { headers }
  );
  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

/* Get Payment Status
 * @createdBy Ankit
 */
async function deleteUserSubscription(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let response = await axios.get(
    `/admin/deleteUserSubscription?payment_intent=${data.payment_intent}`,
    { headers }
  );

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

async function getVideoLink(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let response = await axios.get(`/admin/getVideoLink`, { headers });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// updateVideoLink
async function updateVideoLink(data, token) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  let response = await axios.post(`/admin/updateVideoLink`, data, { headers });

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// get coach profile data
async function getCoachProfileData(token, user_id) {
  // await verifyToken(token);
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json"}
  let response = await axios.post("/admin/accountabilityCoach/getUserProfileData", {user_id},
    { headers }
  );

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    return err;
  }
}

async function createCoachAndAvailability(data, token) {
  // const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // You can add other headers as needed
  };
  let response = await axios.post("/admin/accountabilityCoach/createCoachAndAvailability",
    data,
    { headers }
  );

  try {
    let response_data = response;
    return response_data;
  } catch (err) {
    return err;
  }
}

const combinedServices = {
  createExperienceDetails,
  createEducationDetails,
  createUserPersonalInfomation,
  getUserProfileData,
  addBankDetails,
  saveSubscriptionPlan,
  getAllSubscriptionPlan,
  getDetailsSubscription,
  getDetailsSubscriptionForUser,
  deleteSubscription,
  updateStatusSubscription,
  getDashboardData,
  getPaymentStatus,
  deleteUserSubscription,
  getVideoLink,
  updateVideoLink,
  getAllSubscriptionPlanTable,
  getCoachProfileData,
  createCoachAndAvailability
};

export default combinedServices;

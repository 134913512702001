import React, { useState } from 'react';

function EndYearDropdown(props) {
  const [selectedYear, setSelectedYear] = useState(null);

  // Generate an array of 100 years starting from the current year
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, index) => currentYear - index);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
    if(props.T== "endYear"){
      props.handleChangeYear(e, props.index)
    }else{
      props.handleChangeYear(e);
    };
  };
  return (
    <div className={`mb-3 ${props?.endYearEmpty ? 'errorCont' : ''}`}>
      <select className={`yearDropdownSel ${props?.endYearEmpty ? 'error' : ''}`} name="mbts_endYear" value={props.mbts_endYear} onChange={handleYearChange}>
        <option value="">End Year</option>
        {years.map((year) => (
          <option key={year} value={year} disabled={props.mbts_startYear && year < props.mbts_startYear}>
            {year}
          </option>
        ))}
      </select>
      <small className="error_msg">{props.endYearEmpty}</small>
    </div>
  );
}

export default EndYearDropdown;
import React, { useState, useEffect } from "react";
import Axios from 'axios'
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, Modal, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import { FaCheck } from "react-icons/fa6";
import { LuEye } from "react-icons/lu";
import './subscription.css';
import { BiEdit } from "react-icons/bi"
import { MdDelete } from "react-icons/md";
import Warning from "../../src/assets/warning.svg"
import combinedServices from "../services/service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactLoading from "react-loading";
import ReactPaginate from 'react-paginate';



const Subscription = (props) => {
  const [loader, setLoader] = useState(true);
  const [activeTab, setActiveTab] = useState('1');
  const [subscription, setSubscription] = useState([]);
  const [selectedValue, setSelectedValue] = useState('User');
  const [pageCount, setPageCount] = useState();
  const [totalCount, setTotalCount] = useState('0')
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  {/* */ }
  const [isActive1, setIsActive1] = useState(false);
  const [isActive, setIsActive] = useState({});


  const toggleSwitch = async (planId, status) => {
    let token = localStorage.getItem("token");
    let data = {
      id: planId,
      status: status
    };
    const subscriptionDetailsResponse =
      await combinedServices.updateStatusSubscription(data, token);
    if (subscriptionDetailsResponse.data.code == 200) {
      toast.success("Data Updated Successfully", {
        position: "top-right",
      });
      fetchData();
    }
  };

  const [modal, setModal] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [deleteKey, setDeleteKey] = useState();
  const [planId, setPlanId] = useState();
  const [subscriptionTable, setSubscriptionTable] = useState();
  const toggle1 = (feature) => {
    setModal(!modal);
    setSelectedFeature(feature);
  };
  {/* */ }
  const [modal2, setModal2] = useState(false);
  // const toggle3 = async (id){

  // }

  const toggle3 = async () => {
    setModal2(!modal2);
  }
  const toggle2 = async (id) => {
    setModal2(!modal2);
    let token = localStorage.getItem("token");
    setPlanId(id);
    if (deleteKey == "Delete") {
      let data = {
        _id: planId
      };
      const subscriptionDetailsResponse =
        await combinedServices.deleteSubscription(data, token);
      if (subscriptionDetailsResponse.data.code == 200) {
        setDeleteKey("");
        toast.success("Data Deleted Successfully", {
          position: "top-right",
        });
        setModal2(!modal2);
        fetchData();
      }
    }
  };

  const handleChnageDelete = (e) => {
    setDeleteKey(e.target.value)
  }

  useEffect(() => {
    // Call fetchData inside useEffect
    fetchData();

  }, []);

  useEffect(() => {
    // Call fetchData inside useEffect    
    fetchData2();
  }, []);

  useEffect(() => {
    const initialIsActiveState = {};
    subscription.forEach((plan) => {
      initialIsActiveState[plan._id] = plan.status;
    });
    setIsActive(initialIsActiveState);

  }, [subscription]);


  const fetchData = async () => {
    try {
      let token = localStorage.getItem("token");
      let data = {
        page: page,
        limit: limit,
        search: search
      }
      // const subscriptionDetailsTableResponse = await combinedServices.getAllSubscriptionPlanTable(data , token);
      const subscriptionDetailsResponse =
        await combinedServices.getAllSubscriptionPlan(token);


      if (subscriptionDetailsResponse.data.code == 200) {
        setLoader(false)
        setSubscription(subscriptionDetailsResponse.data.data)
      }

    } catch (error) {
      console.error("Error in getUserDetails request:", error);
    }
  };
  const fetchData2 = async () => {
    try {
      let token = localStorage.getItem("token");
      let data = {
        page: page,
        limit: limit,
        search: search
      }
      const subscriptionDetailsTableResponse = await combinedServices.getAllSubscriptionPlanTable(data, token);
      // const subscriptionDetailsResponse =
      //   await combinedServices.getAllSubscriptionPlan(token);


      if (subscriptionDetailsTableResponse.data.code == 200) {
        setLoader(false)
        // const itemsPerPage = 10; // Number of items to display per page
        // let totalCount = response.data.data.totalCount;
        // const pageCounts = Math.ceil(totalCount / itemsPerPage);
        // setPageCount(pageCounts);
        const itemsPerPage = 10; // Number of items to display per page
        let totalCount = subscriptionDetailsTableResponse.data.data.totalCount;
        const pageCounts = Math.ceil(totalCount / itemsPerPage);
        setPageCount(pageCounts);
        setSubscriptionTable(subscriptionDetailsTableResponse.data.data.data)
      }

    } catch (error) {
      console.error("Error in getUserDetails request:", error);
    }
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handlePageClick = async (event) => {
    setLoader(true)
    let value = event.selected + 1;
    setPage(value);
    let token = localStorage.getItem("token");
    let data = {
      page: value,
      limit: limit,
      search: search
    }
    const subscriptionDetailsTableResponse = await combinedServices.getAllSubscriptionPlanTable(data, token);


    if (subscriptionDetailsTableResponse.data.code == 200) {
      setLoader(false)
      const itemsPerPage = 10; // Number of items to display per page
      let totalCount = subscriptionDetailsTableResponse.data.data.totalCount;
      const pageCounts = Math.ceil(totalCount / itemsPerPage);
      setPageCount(pageCounts);
      setSubscriptionTable(subscriptionDetailsTableResponse.data.data.data)
    }
  };
  return (
    <>
      <div className="middleSection-wrapper" >
        <div className="dashboardOuter">
          <div className="rightSection">
            <div className="subsHdBx" >
              <h1>Subscription Plans</h1>
              <Link to="/dashboard/add-subscription">Add New Plan</Link>
            </div>
            <div className="plantypeBx">
              <div className="innerRadW">
                <label className="radCusCont">User
                  <input type="radio" name="User" value="User" checked={selectedValue === 'User'} onChange={handleRadioChange} />
                  <span className="checkmark"></span>
                </label>
                <label className="radCusCont">Coach
                  <input type="radio" name="User" value="Coach" checked={selectedValue === 'Coach'} onChange={handleRadioChange} />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="subscribe_outer">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { toggle('1'); }}
                  >
                    Monthly
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { toggle('2'); }}
                  >
                    Yearly
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="subsOuter">
                    {subscription
                      .filter(plan => plan.mbts_type === selectedValue && plan.mbts_monthly_price && plan.status)
                      .map((plan, index) => (

                        <div className="subsBx" key={plan._id || index}>
                          <div className="vasictear">
                            <h3>{plan.mbts_title}</h3>
                            <span>
                              <strong>{plan.mbts_currency || '$'}{plan.mbts_monthly_price}/month</strong>
                            </span>
                          </div>
                          <div className="subsList">
                            <ul>
                              {(plan.mbts_feature?.planFeatures || []).filter((feature) => feature.checked).slice(0, 6).map((feature, featureIndex) => (
                                // {(plan.mbts_feature?.planFeatures || []).map((feature, featureIndex) => (
                                feature.checked && (
                                  <li key={featureIndex}>
                                    <i>
                                      <FaCheck />
                                    </i>{' '}
                                    {feature.text}
                                  </li>
                                )
                              ))}
                            </ul>
                          </div>
                          <div className="seeall">
                            <Button onClick={() => toggle1(plan.mbts_feature)}>See All Benefits</Button>
                          </div>
                          <div className="upgrdBtn">
                            <Link to={`/dashboard/add-subscription/${plan._id}`}>Edit Plan</Link>
                          </div>
                        </div>
                      ))}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="subsOuter">
                    {subscription
                      .filter(plan => plan.mbts_type === selectedValue && plan.mbts_yearly_price && plan.status)
                      .map((plan, index) => (

                        <div className="subsBx" key={plan._id || index}>
                          <div className="vasictear">
                            <h3>{plan.mbts_title}</h3>
                            <span>
                              <strong>{plan.mbts_currency || '$'}{plan.mbts_yearly_price}/year</strong>
                            </span>
                          </div>
                          <div className="subsList">
                            <ul>
                              {(plan.mbts_feature?.planFeatures || []).filter((feature) => feature.checked).slice(0, 6).map((feature, featureIndex) => (
                                // {(plan.mbts_feature?.planFeatures || []).map((feature, featureIndex) => (
                                feature.checked && (
                                  <li key={featureIndex}>
                                    <i>
                                      <FaCheck />
                                    </i>{' '}
                                    {feature.text}
                                  </li>
                                )
                              ))}
                            </ul>
                          </div>
                          <div className="seeall">
                            <Button onClick={() => toggle1(plan.mbts_feature)}>See All Benefits</Button>
                          </div>
                          <div className="upgrdBtn">
                            <Link to={`/dashboard/add-subscription/${plan._id}`}>Edit Plan</Link>
                          </div>
                        </div>
                      ))}

                  </div>
                </TabPane>
              </TabContent>
            </div>
            <div className="tableWrapper mt-3">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th width="60" scope="col">#</th>
                    <th>Plan Type</th>
                    <th>Plan Title</th>
                    <th>Monthly Price</th>
                    <th>Yearly Price</th>
                    <th>Created Date</th>
                    <th width="180">Plan Status</th>
                    <th width="110" scope="col">Actions</th>
                  </tr>
                </thead>
                {loader ? (
                  <tbody>

                    <tr>
                      <td colSpan={8} className="tbl_loaderWrp">
                        <div className="loaderCont">
                          <div className="d-flex justify-content-center align-items-center h-100">
                            <ReactLoading type="bars" color="#32B588" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {subscriptionTable?.map((plan, index) => (
                      <tr key={plan._id}>

                        <td>{(page - 1) * limit + index + 1}</td>
                        <td>{plan.mbts_type}</td>
                        <td>{plan.mbts_title}</td>
                        {plan.mbts_monthly_price ? (
                          <td>{plan.mbts_currency}{plan.mbts_monthly_price}/month</td>
                        ) : (
                          <td></td>
                        )}
                        {plan.mbts_yearly_price ? (
                          <td>{plan.mbts_currency}{plan.mbts_yearly_price}/year</td>
                        ) : (
                          <td></td>
                        )}
                        <td className="planDate">
                          {new Date(plan.createdAt).toLocaleDateString()}<br />
                          <small>{new Date(plan.createdAt).toLocaleTimeString()}</small>
                        </td>
                        <td className="actSwitch">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={plan.status}
                              onChange={() => toggleSwitch(plan._id, !plan.status)}
                            />
                            <span className="slider round"></span>
                          </label>
                          <p>{plan.status ? 'Active' : 'Inactive'}</p>
                        </td>
                        <td>
                          <Button className="featureBtn" onClick={() => toggle1(plan.mbts_feature)}><LuEye /><i>View All Features</i></Button>
                          <Link className="editButton" to={`/dashboard/add-subscription/${plan._id}`}>
                            <BiEdit />
                          </Link>
                          {/* <button className="delButton" onClick={() => toggle2(plan._id)}><MdDelete /></button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>

            <div className="list-pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel="next"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="previous"
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
                containerClassName="custom-pagination"
              />
            </div>
          </div>
        </div>
      </div>
      {/*Modal */}
      <Modal isOpen={modal} toggle={toggle1} className="modal_outer">
        <ModalBody>
          <Button className="popupClseBtn" onClick={toggle1}>X</Button>
          <div className='add_bankaccount'>
            <h3>Pro Tier</h3>
            <div className="planspeci">
              <ul>
                {selectedFeature.planFeatures?.map((feature) => (
                  feature.checked && (
                    <li key={feature.id}>
                      <i>
                        <FaCheck />
                      </i>{' '}
                      {feature.text}
                    </li>
                  )
                ))}
                {selectedFeature && (
                  <ul>
                    {selectedFeature?.accessJourneys?.accessJourneys && (
                      <li>
                        <i>
                          <FaCheck />
                        </i>{' '}
                        {selectedFeature?.accessJourneys.accessJourneysText}{' '}{selectedFeature?.accessJourneys.journeyInput}{' '}{'Journeys.'}</li>
                    )}
                    {selectedFeature?.accessJourneys?.unlimitedJourneys && (
                      <li>
                        <i>
                          <FaCheck />
                        </i>{' '}{selectedFeature?.accessJourneys.unlimitedJourneysText}</li>
                    )}
                  </ul>
                )}
                {selectedFeature && (
                  <ul>
                    {selectedFeature?.accountabilityPartner?.connectToPartner && (
                      <li>
                        <i>
                          <FaCheck />
                        </i>{' '}
                        {selectedFeature?.accountabilityPartner.connectToPartnerText}{' '}{selectedFeature?.accountabilityPartner.journelInput}{' '}{'accountability Partners.'}</li>
                    )}
                    {selectedFeature?.accountabilityPartner?.unlimitedPartners && (
                      <li>
                        <i>
                          <FaCheck />
                        </i>{' '}{selectedFeature?.accountabilityPartner.unlimitedPartnersText}</li>
                    )}
                  </ul>
                )}
                {selectedFeature && (
                  <ul>
                    {selectedFeature?.customerSupport?.selectedOption && (
                      <li>
                        <i>
                          <FaCheck />
                        </i>{' '}
                        {selectedFeature?.customerSupport?.selectedOption}</li>
                    )}
                  </ul>
                )}
                {selectedFeature && (
                  <ul>
                    {selectedFeature?.liveCoaching?.liveSessions && (
                      <li>
                        <i>
                          <FaCheck />
                        </i>{' '}
                        {selectedFeature?.liveCoaching.sessionInput}{' '}{selectedFeature?.liveCoaching.sessionTime}{'-'}{'minute'}{''}{selectedFeature?.liveCoaching?.liveSessionsText}</li>
                    )}
                    {selectedFeature?.accountabilityPartner?.unlimitedPartners && (
                      <li>
                        <i>
                          <FaCheck />
                        </i>{' '}{selectedFeature?.accountabilityPartner.unlimitedPartnersText}</li>
                    )}
                  </ul>
                )}

                {/* <h2>{selectedFeature.accessJourneysText}</h2> */}
                {/* <li><i><FaCheck /></i> Access to the Progressional software and all core features. </li>
                            <li><i><FaCheck /></i> Goal setting and progress tracking.</li>
                            <li><i><FaCheck /></i> Visualization tools. </li>
                            <li><i><FaCheck /></i> Self-guided exercises and tools.</li>
                            <li><i><FaCheck /></i> Access to three life categories (e.g., Fitness, Finance, Focus). </li>
                            <li><i><FaCheck /></i> Access to the Progressional software and all core features. </li>
                            <li><i><FaCheck /></i> Goal setting and progress tracking.</li>
                            <li><i><FaCheck /></i> Visualization tools. Self-guided exercises and tools.</li>
                            <li><i><FaCheck /></i> Access to three life categories (e.g., Fitness, Finance, Focus).</li>
                            <li><i><FaCheck /></i> Access to the Progressional software and all core features.</li>
                            <li><i><FaCheck /></i> Goal setting and progress tracking. </li>
                            <li><i><FaCheck /></i> Visualization tools. </li>
                            <li><i><FaCheck /></i> Self-guided exercises and tools.</li>
                            <li><i><FaCheck /></i> Access to three life categories (e.g., Fitness, Finance, Focus).</li> */}
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*Modal */}
      {/*Delete Modal*/}
      <Modal isOpen={modal2} toggle={toggle2} className="modal_outer">
        <ModalBody>
          <div className='sure_message'>
            <i><img src={Warning} alt="" /></i>
            <h2>Delete Subscription Plan</h2>
            <p>To delete the board type <span className='bld'>&quot;Delete&quot;</span> in the below text field and click on the delete
              button.
            </p>
            <div className="form_grp confGrp">
              <input type="text" className="form_ctrl" placeholder='Type' name="delete" onChange={handleChnageDelete} />
            </div>
            <div className='modal_button'>
              <Button className="cancel_button" onClick={toggle3}>cancel</Button>
              <Button className="confirmation_button" onClick={toggle2}>delete</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*Delete Modal*/}
    </>
  );
}

export default Subscription
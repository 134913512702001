import React, { Component, } from 'react'
import Header from "../Components/CommonComponents/Header/Header";
import Sidebar from '../Components/CommonComponents/Sidebar/Sidebar';
import Questions from '../Questions/Questions'
import Footer from '../Components/CommonComponents/Footer/Footer'
import { hasToken } from '../utils/tokenUtil';
import { useNavigate } from 'react-router-dom';

export default function Transcript() {
        const navigate = useNavigate();
        if (!hasToken()) {
            navigate('/'); // Redirect to the login page or any other appropriate location
            return null; // Render nothing
          }
        return (
            <>
              <Header />
              <Sidebar />
              <Questions />
              <Footer/>
            </>
        );  


}

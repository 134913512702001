import React, { useState, useEffect } from "react";
// import Link from "next/link";
import { BsCheck } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { Button, Input, Modal, ModalBody } from "reactstrap";
import "./SubscriptionDetail.css";
import combinedServices from "../services/service";
import { useParams } from "react-router-dom";
import Axios from "axios";
import NoDataFound from "../assets/nodata.svg";
import { ToastContainer, toast } from "react-toastify";

const SubscriptionDetail = () => {
  const { userId } = useParams();
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(true);
  const [subscripion, setSubscription] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [activatedOn, setActivatedOn] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);
  const [modal2, setModal2] = useState(false);
  const [reload, setReload] = useState(false);

  const toggle1 = () => {
    setModal(!modal);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  useEffect(() => {
    // Call fetchData inside useEffect
    fetchData(userId);
  }, [userId, reload]);

  // getting detail of current subsciption
  const fetchData = async () => {
    try {
      let token = localStorage.getItem("token");

      const body = {
        user_id: userId,
      };
      const response = await Axios.post("/admin/getUserDetailsForAdmin", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.code == 200) {
        const subscriptionId = response.data.data.subscription_id;

        const subscriptionDetailsResponse =
          await combinedServices.getDetailsSubscriptionForUser(
            subscriptionId,
            userId,
            token
          );


        if (subscriptionDetailsResponse.data.code == 200) {
          setLoader(false);
          setSubscription(subscriptionDetailsResponse.data.data);
          const activatedOn = new Date(
            subscriptionDetailsResponse?.data?.data?.date_created
          );
          let expirationDate = new Date(
            subscriptionDetailsResponse.data?.data?.mbts_exepiration_data
          );

          setExpirationDate(expirationDate);
          setActivatedOn(activatedOn);

          const currentDate = new Date();
          if (expirationDate > currentDate) {
            const daysDifference = Math.floor(
              (expirationDate.getTime() - currentDate.getTime()) /
                (1000 * 60 * 60 * 24)
            );
            setDaysLeft(daysDifference);
          }
        }
      }
    } catch (error) {
      console.error("Error in getUserDetails request:", error);
    }
  };

  function formatDate(date) {
    const d = new Date(date);
    const day = d.getUTCDate().toString().padStart(2, "0");
    const month = (d.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = d.getUTCFullYear().toString().slice(-0);
    return `${day}/${month}/${year}`;
  }

  const extendPlan = async () => {
    try {
      let token = localStorage.getItem("token");
      const body = {
        mbts_user_id: userId,
        subscription_id: subscripion?._id,
      };

      // /extendSubscription
      const response = await Axios.post("/admin/extendSubscription", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.code == 200) {
        toast.success("Plan extended successfully");
        setReload(!reload);
        toggle2();
      } else {
        toast.error("Error in extending plan");
      }
    } catch (error) {
      console.error("Error in getUserDetails request:", error);
    }
  };

  return (
    <>
      <div className="edumain">
        <div className="expense-block">
          <div className="editProfFrmBx">
            <div className="transOuterBx">
              <div className="currentplanHd">
                <h3>Current Plan</h3>
                <div className="planRight">
                  <span>
                    Plan Activated On:{" "}
                    <strong>
                      {activatedOn ? formatDate(activatedOn) : ""}
                    </strong>
                  </span>
                  <span>
                    Plan Expiring On:{" "}
                    <strong>
                      {expirationDate ? formatDate(expirationDate) : ""}
                    </strong>
                  </span>
                  <small className={` ${daysLeft == null ? "expired" : ""}`}>
                    {daysLeft !== null ? `${daysLeft} Days Left` : "Expired"}
                  </small>
                </div>
              </div>
              <div className="current_planOuterBx">
                <div className="planLeft">
                  <div className="planAmount">
                    <span>{subscripion?.mbts_title}</span>
                    {subscripion?.plan_time == "monthly" ? (
                      <h2>
                        {"$"}
                        {subscripion?.mbts_monthly_price}
                        <small>/month</small>
                      </h2>
                    ) : subscripion?.plan_time == "yearly" ? (
                      <h2>
                        {"$"}
                        {subscripion?.mbts_yearly_price}
                        <small>/year</small>
                      </h2>
                    ) : (
                      <h2>
                        {"$"}
                        {subscripion?.mbts_yearly_price}
                        <small>/14-days</small>
                      </h2>
                    )}
                  </div>
                  {/* <Link
                href={`/dashboard/subscription?plan_title=${subscripion?.mbts_title}`}
                className="upgradePlanBtn"
              >
                Upgrade Plan
              </Link> */}
                  {/* <a href="/" className="upgradePlanBtn">
                    Extend Plan
                  </a> */}
                  <Button onClick={toggle2} className="upgradePlanBtn">
                    Extend Plan
                  </Button>
                </div>
                <div className="planFeat_Right">
                  {subscripion ? (
                    <div className="subsPoints">
                      <ul>
                        {(subscripion?.mbts_feature?.planFeatures || [])
                          .filter((feature) => feature.checked)
                          .slice(0, 12)
                          .map((feature, featureIndex) => (
                            <li key={featureIndex}>
                              <BsCheck /> {feature.text}
                            </li>
                          ))}
                      </ul>
                      <ul>
                        {subscripion?.mbts_feature && (
                          <>
                            {subscripion?.mbts_feature?.accessJourneys
                              ?.accessJourneysText && (
                              <li>
                                <BsCheck />
                                {
                                  subscripion?.mbts_feature?.accessJourneys
                                    .accessJourneysText
                                }{" "}
                                {
                                  subscripion?.mbts_feature?.accessJourneys
                                    .journeyInput
                                }{" "}
                                {"Journeys."}
                              </li>
                            )}
                            {subscripion?.mbts_feature?.accessJourneys
                              ?.unlimitedJourneys && (
                              <li>
                                <BsCheck />
                                {
                                  subscripion?.mbts_feature?.accessJourneys
                                    .unlimitedJourneysText
                                }
                              </li>
                            )}
                          </>
                        )}
                        {subscripion?.mbts_feature && (
                          <>
                            {subscripion?.mbts_feature?.accountabilityPartner
                              ?.connectToPartnerText && (
                              <li>
                                <BsCheck />
                                {
                                  subscripion?.mbts_feature
                                    .accountabilityPartner.connectToPartnerText
                                }{" "}
                                {
                                  subscripion.mbts_feature.accountabilityPartner
                                    .journelInput
                                }{" "}
                                {"accountability Partners."}
                              </li>
                            )}
                            {subscripion?.mbts_feature.accountabilityPartner
                              ?.unlimitedPartnersText && (
                              <li>
                                <BsCheck />
                                {
                                  subscripion.mbts_feature.accountabilityPartner
                                    .unlimitedPartnersText
                                }
                              </li>
                            )}
                          </>
                        )}
                        {subscripion?.mbts_feature?.customerSupport
                          ?.selectedOption && (
                          <li>
                            <BsCheck />
                            {
                              subscripion?.mbts_feature.customerSupport
                                .selectedOption
                            }
                          </li>
                        )}
                        {subscripion?.mbts_feature?.liveCoaching
                          ?.liveSessions && (
                          <li>
                            <BsCheck />
                            {
                              subscripion?.mbts_feature.liveCoaching
                                .sessionInput
                            }{" "}
                            {subscripion?.mbts_feature.liveCoaching.sessionTime}
                            {"-"}
                            {"minute"}
                            {""}
                            {
                              subscripion.mbts_feature.liveCoaching
                                .liveSessionsText
                            }
                          </li>
                        )}
                      </ul>
                    </div>
                  ) : (
                    <div className="noData">
                      <img src={NoDataFound} alt="nodatafound" />
                      <p>no data found</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="allbenefits">
                <Button onClick={toggle1}>See All Benefits</Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Modal */}
      <Modal isOpen={modal} toggle={toggle1} className="modal_outer">
        <ModalBody>
          <Button className="popupClseBtn" onClick={toggle1}>
            X
          </Button>
          <div className="sure_message">
            <h3>{subscripion?.mbts_title}</h3>
            <div className="planspeci">
              <ul>
                {(subscripion?.mbts_feature?.planFeatures || [])
                  .filter((feature) => feature.checked)
                  .slice(0, 12)
                  .map((feature, featureIndex) => (
                    <li key={featureIndex}>
                      <BsCheck /> {feature.text}
                    </li>
                  ))}
                {subscripion?.mbts_feature && (
                  <>
                    {subscripion.mbts_feature?.accessJourneys
                      ?.accessJourneysText && (
                      <li>
                        <BsCheck />
                        {
                          subscripion?.mbts_feature?.accessJourneys
                            .accessJourneysText
                        }{" "}
                        {subscripion?.mbts_feature?.accessJourneys.journeyInput}{" "}
                        {"Journeys."}
                      </li>
                    )}
                    {subscripion.mbts_feature?.accessJourneys
                      ?.unlimitedJourneys && (
                      <li>
                        <BsCheck />
                        {
                          subscripion.mbts_feature?.accessJourneys
                            .unlimitedJourneysText
                        }
                      </li>
                    )}
                  </>
                )}
                {subscripion?.mbts_feature && (
                  <>
                    {subscripion.mbts_feature?.accountabilityPartner
                      ?.connectToPartnerText && (
                      <li>
                        <BsCheck />
                        {
                          subscripion.mbts_feature.accountabilityPartner
                            .connectToPartnerText
                        }{" "}
                        {
                          subscripion.mbts_feature.accountabilityPartner
                            .journelInput
                        }{" "}
                        {"accountability Partners."}
                      </li>
                    )}
                    {subscripion.mbts_feature.accountabilityPartner
                      ?.unlimitedPartnersText && (
                      <li>
                        <BsCheck />
                        {
                          subscripion.mbts_feature.accountabilityPartner
                            .unlimitedPartnersText
                        }
                      </li>
                    )}
                  </>
                )}
                {subscripion?.mbts_feature?.customerSupport?.selectedOption && (
                  <li>
                    <BsCheck />
                    {subscripion.mbts_feature.customerSupport.selectedOption}
                  </li>
                )}
                {subscripion?.mbts_feature?.liveCoaching?.liveSessions && (
                  <li>
                    <BsCheck />
                    {subscripion.mbts_feature.liveCoaching.sessionInput}{" "}
                    {subscripion.mbts_feature.liveCoaching.sessionTime}
                    {"-"}
                    {"minute"}
                    {""}
                    {subscripion.mbts_feature.liveCoaching.liveSessionsText}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/*Modal */}

      {/* modal for extend plan */}

      <Modal isOpen={modal2} toggle={toggle2} className="modal_outer">
        <ModalBody>
          <Button className="popupClseBtn" onClick={toggle2}>
            X
          </Button>
          <div className="sure_message">
            <h3>Plan Extension</h3>

            <p>
            By selecting this feature, the user will get one-month plan extension.
            </p>

            {/* <Button className="upgradePlanBtn" onClick={extendPlan}>
              Extend
            </Button> */}
            <div className='modal_button text-center closeBtn'>
                            <Button className="cancel_button" onClick={extendPlan}>Extend</Button>
                        </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SubscriptionDetail;
